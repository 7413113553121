import T from 'terrific';
import $ from 'jquery';

T.Module.RootUnit = T.createModule({

	_selectors: {
		searchInput: '#js-consumer-search-input',
		searchBtn: '[js-consumer-search-btn]',
		searchResultDiv: '#js-consumer-search-result',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$ctx.find(this._selectors.searchBtn).click(this.onClickSearchBtn.bind(this));
		this.$ctx.find(this._selectors.searchInput).keyup(this.validateInput.bind(this));
		this.$ctx.find(this._selectors.searchInput).keypress(this.onKeyPressSearchInput.bind(this));
		resolve();
	},

	onKeyPressSearchInput(ev) {
		if (ev.which === 13) {
			this.$ctx.find(this._selectors.searchBtn).click();
		}
	},

	validateInput(ev) {
		this.$ctx = $(this._ctx);
		const selectors = this._selectors;
		const $el = $(ev.target);
		const value = $el.val().toString();
		if (value.trim().length > 0) {
			this.$ctx.find(selectors.searchBtn).removeAttr('disabled');
		} else {
			this.$ctx.find(selectors.searchBtn).attr('disabled', true);
		}
	},

	onClickSearchBtn() {
		this.$ctx = $(this._ctx);
		const selectors = this._selectors;
		const searchText = this.$ctx.find(selectors.searchInput).val();
		const resultDiv = this.$ctx.find(selectors.searchResultDiv);
		if (searchText.trim().length === 0) {
			$(resultDiv).html('');
			return;
		}
		const searchUrl = this.$ctx.find(selectors.searchBtn).attr('js-searchUrl');
		$.ajax({
			url: `${searchUrl}?text=${searchText}`,
			type: 'GET',
			dataType: 'html',
			success: (data) => {
				const responseData = `${data}`;
				$(resultDiv).html(responseData);
			},
			error: (error) => {
				/* eslint-disable no-console */
				console.log(`Failed to search B2BUnit => ${error}`);
			},
		});
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

});
