// @ts-check
import { endPoints } from '@dormakaba/ajax';
import { addNotification, MessageType } from '@dormakaba/notifications';
import $ from 'jquery';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';

/** @type {any} T - Global Terrific Namespace*/
const T = require('terrific');

/**
 * Basic Module description.
 */
T.Module.AddToCartButton = T.createModule({
	_configs: {
		outOfStock: 'outOfStock',
	},
	_selectors: {
		addToCartForm: '[data-js-add-to-cart-form]',
		addToCartButton: '[data-js-add-to-cart-button-enable-button]',
	},
	_eventNames: {
		CLICK: 'click',
	},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$ajaxCallEvent = true;

		this.$addToCartForm = this.$ctx.find(this._selectors.addToCartForm);
		this.$addToCartButtons = this.$ctx.find(this._selectors.addToCartButton);

		this.$addToCartButtons.on(this._eventNames.CLICK, this.cartSubmit.bind(this));

		this.disableAddToCartButtons(false);

		this._events.on('setHiddenQuantityField', (qtyInput, target, invalid) => {
			this.setHiddenQuantityField(qtyInput, target, invalid);
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	// Enable/disable addToCartButton
	disableAddToCartButtons(bool = true) {
		this.$addToCartButtons.each((index, el) => {
			const $el = $(el);
			if (!$el.hasClass(this._configs.outOfStock)) {
				$el.prop('disabled', bool);
				$('div[data-react-app="item-list-pdp"] button').prop('disabled', bool);
			}
		});
	},

	// Change value in hidden input field (reset if below minimum or empty)
	setHiddenQuantityField(qtyInput, target, invalid) {
		const $qtyInput = $(qtyInput);
		const $target = $(target);
		const min = $qtyInput.prop('min');
		const minVal = Number(min);
		const qtyVal = Number(qtyInput.value);

		if (qtyVal < minVal || qtyVal === 0) {
			$target.val(minVal);
			$qtyInput.val(minVal);
			invalid = false;
		} else if (invalid) {
			$target.val(minVal);
		} else {
			$target.val(qtyVal);
			$qtyInput.val(qtyVal);
		}

		this.disableAddToCartButtons(invalid);
	},

	cartSubmit(event) {
		event.preventDefault();
		this.disableAddToCartButtons(true);

		endPoints.ekitTerrificHybrisController.postAddToCart.ajax({
			keys: {
				encodedContextPath: TerrificConfig.config.encodedContextPath,
				serializedForm: this.$addToCartForm.serialize(),
			},
		}).then((data) => {
			let messageType;
			switch (data.type) {
				case 'SUCCESS':
					messageType = MessageType.SUCCESS;
					break;
				case 'WARNING':
					messageType = MessageType.WARNING;
					break;
				case 'ERROR':
					messageType = MessageType.ERROR;
					break;
				default:
					messageType = MessageType.WARNING;
			}
			addNotification(messageType, data.type.toLowerCase(), data.message);
			this.disableAddToCartButtons(false);
			this._events.emit('cartUpdated');
			if (typeof window.CustomEvent === 'function') {
				document.dispatchEvent(new CustomEvent('refreshMiniCart'));
			}
		});
	},
});
