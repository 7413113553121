import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.Form = T.createModule({
	_attributes: {
		data: {
			conditionAttributeTarget: 'js-form-condition-attribute-target',
		},
	},
	_selectors: {
		conditionAttributeTrigger: '[data-js-form-condition-attribute-trigger]',
		conditionAttributeCreditBalanceTrigger: '[data-js-form-condition-attribute-creditBalance-trigger]',
		changeSubmitTrigger: '[data-js-form-change-submit-trigger]',
		submitButton: 'button[type="submit"]',
	},
	_eventNames: {
		CHANGE: 'change',
		CLICK: 'click',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.$submitButton = this.$ctx.find(this._selectors.submitButton);
		this.$creditBalanceTrigger = this.$ctx.find(this._selectors.conditionAttributeCreditBalanceTrigger);
		this.$conditionTrigger = this.$ctx.find(this._selectors.conditionAttributeTrigger);
		this.$conditionTrigger.prop('checked', false);
		this.$creditBalanceTrigger.prop('checked', false);
		this.$conditionTrigger.on(this._eventNames.CHANGE, this.onConditionAttributeTriggerChange.bind(this,
			this.$creditBalanceTrigger));
		this.$creditBalanceTrigger.on(this._eventNames.CHANGE, this.onConditionAttributeTriggerChange.bind(this,
			this.$conditionTrigger));
		this.$ctx.find(this._selectors.changeSubmitTrigger)
			.on(this._eventNames.CHANGE, this.submitTargetForm.bind(this, null));
		this.$submitButton.on(this._eventNames.CLICK, this.submitTargetForm.bind(this, null));
		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	onConditionAttributeTriggerChange(checkbox, event) {
		const target = $(event.target).data(this._attributes.data.conditionAttributeTarget);
		const isCreditBalanceTermsCheckNotRequired = $('#creditBalancetermsCheck').length === 0;
		const isOtherCheckboxSelected = $(checkbox).is(':checked') === true;
		const isEventCheckboxSelected = $(event.currentTarget).is(':checked') === true;
		if ((isCreditBalanceTermsCheckNotRequired || isOtherCheckboxSelected)
			&& isEventCheckboxSelected) {
			$(target).prop('disabled', false);
		} else {
			$(target).prop('disabled', true);
		}
	},

	submitTargetForm(index, ev) {
		const changeSubmitTrigger = this._ctx.querySelector(this._selectors.changeSubmitTrigger);
		// WADHYB-352: If target an external trigger, fire submit()
		if (changeSubmitTrigger && changeSubmitTrigger === ev.target) {
			if (!Boolean($(ev.target).is(':invalid'))) {
				if (this.$submitButton) {
					this.$submitButton.prop('disabled', true);
				}
				ev.target.form.submit();
			}
		}
	},
});
