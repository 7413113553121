import T from 'terrific';
import $ from 'jquery';


T.Module.CheckAvailabilityButton = T.createModule({

	_selectors: {
		checkAvailabilityButton: '[data-js-check-availability-button]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {},

	start(resolve) {

		this.$ctx = $(this._ctx);
		this.$ctx.on('click', this._selectors.checkAvailabilityButton, this.onClickCheckAvailability.bind(this));
		resolve();
	},
	onClickCheckAvailability(ev) {
		ev.preventDefault();
		const $target = $(ev.target);
		const selectors = this._selectors;

		this.$ctx = $(this._ctx);
		$target.unbind('click');
		const method = 'GET';
		const $checkAvailabilityButton = this.$ctx.find(selectors.checkAvailabilityButton);
		const url = $checkAvailabilityButton.attr('data-js-check-url');

		$.ajax({
			url,
			type: method,
			dataType: 'html',
			success: (data) => {
				const responseData = `${data}`;
				$('#js-check-availibility-content').html(responseData);
			},
			error: (error) => {
				/* eslint-disable no-console */
				console.log(`Failed to find the get Availibility Details => ${error}`);
				/* eslint-disable no-console */
			},
		});
	},
});
