import * as sharedVariables from '@dormakaba/one-ui/assets/css/variables/_variables.scss';
import T from 'terrific';
import $ from 'jquery';
import 'slick-carousel';
import { _slickGlobalConfigs } from '../../../../assets/js/configs/slick/slick';

/**
 * Basic Module description.
 */
T.Module.AddressCarousel = T.createModule({
	_configs: {
		// This key should be used directly as a slick configuration object
		// TODO: make this configuration global as default configuration of slick sliders
		_slick: {
			dots: true,
			responsive: [
				{
					breakpoint: sharedVariables.sm,
					settings: {
						arrows: true,
					},
				},
				{
					breakpoint: sharedVariables.md,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						arrows: true,
					},
				},
				{
					breakpoint: sharedVariables.lg,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						arrows: true,
					},
				},
			],
		},
	},
	_selectors: {
		slider: '[data-js-address-carousel-slick-slider]',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.$sliders = this.$ctx.find(this._selectors.slider);

		this.initSlider();

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	initSlider() {
		const slickConfig = Object.assign(_slickGlobalConfigs, this._configs._slick);

		this.$sliders.each((index, slider) => {
			$(slider).slick(slickConfig);
		});
	},
});
