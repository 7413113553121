import '@fancyapps/fancybox';
import $ from 'jquery';
import T from 'terrific';
import 'prevent-window-opener-attacks';
import { getFancyboxConfig } from '@dormakaba/one-ui/components/configs/fancybox/fancybox'; // eslint-disable-line import/no-unresolved, max-len
import '../media/dormakaba.ico';
import '../media/dormakaba-touch-icon-192x192.png';

/**
 * App Module description.
 */
T.Module.App = T.createModule({
	_selectors: {
		overlayTrigger: '[data-fancybox-trigger]',
		mainOverlay: '[data-js-main-overlay]',
		onDemandClose: '[data-fancybox-ondemand-close]',
		mainContainer: '[data-js-main-container]',
		fancyboxImages: '[data-js-fancybox]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {
		isLocked: 'o-app--is-locked',
		scrollbarPreserved: 'o-app--scrollbar-preserved',
		mainOverlayIsActive: 'o-app__main-overlay--is-active',
	},
	_configs: {},

	start(resolve) {
		this.$ctx = $(this._ctx);

		this.$mainOverlay = this.$ctx.find(this._selectors.mainOverlay);
		this.$overlayTrigger = this.$ctx.find(this._selectors.overlayTrigger);
		this.$overlayTrigger.on(this._eventNames.CLICK, this.onTriggerOverlay.bind(this));
		this._events.on(T.EventName.Utils.LAYER_ONDEMAND, this.openOverlay.bind(this));

		// initialize breakpoint value
		this.breakpoint = this.getBreakpoint();

		// initialize isMobile and isTouch
		this.isMobile = this.getIsMobileTouchDevice();
		this.isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

		// extend and init fancybox defaults with terrific scoped config
		this.initializeFancybox();

		// init optimized resize
		this.throttle('resize', 'optimizedResize');
		$(window).on('optimizedResize', this.$onOptimizedResize.bind(this));

		// init optimized scroll
		this.throttle('scroll', 'optimizedScroll');
		$(window).on('optimizedScroll', this.$onOptimizedScroll.bind(this));

		this.$mainOverlay.on(this._eventNames.CLICK, () => {
			this._events.emit('mainOverlayClicked');
		});

		this._events.on('showMainOverlay', () => {
			this.showMainOverlay();
		});

		this._events.on('hideMainOverlay', () => {
			this.hideMainOverlay();
		});

		this._events.on('bindClickToBodyToCloseActiveToggle', () => {
			this.bindClickToBodyToCloseActiveToggle();
		});

		this._events.on('unBindClickToBodyToCloseActiveToggle', () => {
			this.unBindClickToBodyToCloseActiveToggle();
		});

		this._events.on('scrollToTop', () => {
			this.scrollToTop();
		});

		this._events.on('t.sync', this.sync.bind(this));

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
		this._events.emit('breakpointChange', {
			breakpoint: this.breakpoint,
		});
	},

	initializeFancybox() {
		this.defaultConfig = $.extend($.fancybox.defaults, getFancyboxConfig());

		// images should use the full width extension
		const $images = this.$ctx.find(this._selectors.fancyboxImages);
		$images.fancybox(getFancyboxConfig({ width: 'full' }));
	},

	getBreakpoint() {
		// read out the content of the bodies ::before-pseudo-element
		// source: https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
		return window.getComputedStyle(this._ctx, ':before').getPropertyValue('content').replace(/["']/g, '');
	},

	getIsMobileTouchDevice() {
		// source: jquery.fancybox.js
		return document.createTouch !== undefined
			&& (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);
	},

	throttle(type, name, obj) {
		obj = obj || window;
		let running = false;
		const func = function () {
			if (running) { return; }
			running = true;
			requestAnimationFrame(() => {
				let event;
				if (typeof(CustomEvent) === 'function') {
					event = new CustomEvent(name);
				} else {
					event = document.createEvent('CustomEvent');
					event.initEvent(name, true, true);
				}
				obj.dispatchEvent(event);
				running = false;
			});
		};
		obj.addEventListener(type, func);
	},

	$onOptimizedResize(ev) {
		this._events.emit('windowResize', {
			ev,
		});

		const breakpoint = this.getBreakpoint();

		if (breakpoint !== this.breakpoint) {
			this._events.emit('breakpointChange', {
				breakpoint,
			});
			this.breakpoint = breakpoint;
		}
	},

	$onOptimizedScroll(ev) {
		this._events.emit('windowScroll', {
			ev,
		});
	},

	showMainOverlay() {
		this.$mainOverlay.addClass(this._stateClasses.mainOverlayIsActive);

		// preserve scrollbar to prevent view jumps
		if (window.innerWidth > document.documentElement.clientWidth) {
			this.$ctx.addClass(this._stateClasses.scrollbarPreserved);
		}

		// Lock scrolling
		this.$ctx.addClass(this._stateClasses.isLocked);
	},

	hideMainOverlay() {
		this.$mainOverlay.removeClass(this._stateClasses.mainOverlayIsActive);

		// Remove scrollbar compensation padding
		this.$ctx.css('padding-right', '');

		// Listener for this event currently not in use but might be helpful for future implementations
		this._events.emit('mainOverlayHide');

		// Unlock scrolling
		this.$ctx.removeClass(this._stateClasses.isLocked);
		this.$ctx.removeClass(this._stateClasses.scrollbarPreserved);
	},

	openOverlay(src) {
		const config = this.defaultConfig;
		config.afterClose = this.onCloseOverlay.bind(this);
		config.afterShow = (instance, current) => {
			const closeBtn = current.$content.find(this._selectors.onDemandClose);
			closeBtn.on(this._eventNames.CLICK, () => {
				instance.close();
			});
			this._sandbox.addModules(current.$content.get(0));
		};

		$.fancybox.open({
			src,
			type: 'inline',
			opts: config,
		});
	},

	onTriggerOverlay(ev) {
		const srcId = $(ev.currentTarget).data('src');
		const src = this.$ctx.find(srcId)[0];
		this.openOverlay(src);
	},

	// https://github.com/fancyapps/fancybox/issues/1681
	onCloseOverlay(ev, instance) {
		if (instance.$content.length > 0) {
			instance.$content.removeAttr('style');
		}
	},

	bindClickToBodyToCloseActiveToggle() {
		this.$ctx.find(this._selectors.mainContainer)
			.on(this._eventNames.CLICK, this.onBodyClickToCloseHeaderSearch.bind(this));
	},

	onBodyClickToCloseHeaderSearch() {
		this._events.emit('closeActiveToggle');
	},

	unBindClickToBodyToCloseActiveToggle() {
		// TODO: Check if problems with deleting all event on mainContainer
		this.$ctx.find(this._selectors.mainContainer)
			.off(this._eventNames.CLICK);
	},

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	},
});

export default T.Module.App;
