import T from 'terrific';
import $ from 'jquery';
import * as toggle from '../../../../assets/js/utils/toggle';

/**
 * Basic Module description.
 */
T.Module.MainNav = T.createModule({
	_selectors: {
		// Desktop
		mainNavLink: '[data-js-main-nav-list-link]',
		mainNavFlyout: '[data-js-main-nav-flyout-wrapper-desktop]',
		mainNavFlyoutColumnWrapper: '[data-js-main-nav-flyout-column-wrapper]',
		mainNavFlyoutMainCol: '[data-js-main-nav-flyout-main-col]',
		mainNavFlyoutColumn: '[data-js-main-nav-flyout-column]',
		mainNavFlyoutList: '[data-js-main-nav-flyout-list]',
		mainNavFlyoutLink: '[data-js-main-nav-flyout-link]',
		mainNavFlyoutBackLink: '[data-js-main-nav-flyout-back-link]',
		// Mobile
		mainNavMobileTrigger: '[data-js-main-nav-burger]',
		mainNavMobileWrapper: '[data-js-main-nav-flyout-wrapper-mobile]',
		mainNavMobileRootCard: '[data-js-main-nav-mobile-flyout-root-card]',
		mainNavMobileFlyoutCard: '[data-js-main-nav-mobile-flyout-card]',
		mainNavMobileListLink: '[data-js-main-nav-mobile-list-link]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {
		// Desktop
		itemIsActive: 'm-main-nav__list-link--is-active',
		flyoutIsActive: 'm-main-nav__flyout-wrapper--is-active',
		flyoutColumnIsLast: 'm-main-nav__flyout-column--is-last',
		flyoutListHasBacklink: 'm-main-nav__flyout-list--has-backlink',
		flyoutItemIsCurrent: 'm-main-nav__flyout-item--is-current',
		flyoutItemIsParent: 'm-main-nav__flyout-item--is-parent',
		// Mobile
		mobileFlyoutCardIsLast: 'm-main-nav__mobile__flyout-card--is-last',
		mobileListItemIsCollapsed: 'm-main-nav__mobile__list-item--is-collapsed',
		mobileListItemIsParent: 'm-main-nav__mobile__list-item--is-parent',
		mobileListItemIsCurrent: 'm-main-nav__mobile__list-item--is-current',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.$burgerMenu = this.$ctx.find(this._selectors.mainNavMobileTrigger);
		this.$mobileFlyoutRootCard = this.$ctx.find(this._selectors.mainNavMobileRootCard);
		this.$flyoutMainColumn = this.$ctx.find(this._selectors.mainNavFlyoutMainCol);

		this.$burgerMenu.on(this._eventNames.CLICK, (ev) => {
			ev.stopPropagation();
			this.toggleMobileFlyout(ev);
		});

		// Clicks on overlay (optically, desktop only)
		$(this._selectors.mainNavFlyout).on(this._eventNames.CLICK, (ev) => {
			this.closeNavigation(ev);
		});

		// Clicks on top level nav containing subitems
		$(this._selectors.mainNavLink).on(this._eventNames.CLICK, (ev) => {
			ev.preventDefault();
			this.toggleFlyout(ev);
		});

		// Clicks on second/deeper level nav containing subitems
		$(this._selectors.mainNavFlyoutLink).on(this._eventNames.CLICK, (ev) => {
			this.clickFlyoutItem(ev);
		});

		// Clicks on <a> of back item in leftmost vertical menu
		$(this._selectors.mainNavFlyoutBackLink).on(this._eventNames.CLICK, (ev) => {
			ev.preventDefault();
			this.clickBackLink(ev);
		});

		this._events.on('toggableMenuClicked', (targetDevice, sendingTriggerSelector) => {
			// Only desktop main nav has toggable counterparts
			if (targetDevice === 'desktop') {
				if (sendingTriggerSelector !== this._selectors.mainNavLink) {
					// Close desktop navigation by triggering a click outside the menu
					$(this._selectors.mainNavFlyout).first().trigger(this._eventNames.CLICK);
				}
			}
		});

		// Mobile

		// Listen to mainOverlay click events from o-app
		this._events.on('mainOverlayClicked', () => {
			if (toggle.isActive(this.$ctx, this._selectors.mainNavMobileTrigger)) {
				this.$burgerMenu.trigger(this._eventNames.CLICK);
			}
		});

		// Clicks on second/deeper level nav containing subitems
		$(this._selectors.mainNavMobileListLink).on(this._eventNames.CLICK, (ev) => {
			this.clickMobileCardItem(ev);
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	/**
	 * Toggle flyout for each top level menu entry.
	 * Note: Each top level menu items has its own flyout container.
	 *
	 * @param {Object} ev JS event of a desktop top navigation entry click target
	 * @returns {undefined}
	 */
	toggleFlyout(ev) {
		const $target = $(ev.currentTarget);
		const $targetFlyout = $target.parent().find(this._selectors.mainNavFlyout);

		this.resetSubmenues();

		if ($targetFlyout.hasClass(this._stateClasses.flyoutIsActive)) {
			this.hideFlyout();
			this.hideOverlay();
		} else {
			// Notify other toggable desktop menus to close as needed
			this._events.emit('toggableMenuClicked', 'desktop', this._selectors.mainNavLink);

			this.hideFlyout();
			$targetFlyout.addClass(this._stateClasses.flyoutIsActive);
			$target.addClass(this._stateClasses.itemIsActive);
			this.markLastColumn();
			this.showOverlay();
		}
	},

	showOverlay() {
		this._events.emit('showMainOverlay');
	},

	hideOverlay() {
		this._events.emit('hideMainOverlay');
	},

	hideFlyout() {
		// Hide flyouts
		this.$ctx
			.find(`.${this._stateClasses.flyoutIsActive}`)
			.removeClass(this._stateClasses.flyoutIsActive);
		// Hide all top level menu item highlighting
		this.$ctx
			.find(`.${this._stateClasses.itemIsActive}`)
			.removeClass(this._stateClasses.itemIsActive);
	},

	/**
	 * Closes and resets flyout navigation and overlay, if user clicks on overlay
	 * (which technically is either the flyout wrapper or the nested column wrapper!).
	 *
	 * @param  {Object} ev JS Event object
	 * @returns {undefined}
	 */
	closeNavigation(ev) {
		const $target = $(ev.target);

		if ($target.is(this._selectors.mainNavFlyoutColumnWrapper)
			|| $target.is(this._selectors.mainNavFlyout)) {

			this.resetSubmenues();
			this.hideFlyout();
			this.hideOverlay();
		}
	},

	/**
	 * Resets visibility states and horizontal positions of submenus
	 *
	 * @returns {undefined}
	 */
	resetSubmenues() {
		// Hide all current flyout items
		this.$ctx
			.find(`.${this._stateClasses.flyoutItemIsCurrent}`)
			.removeClass(this._stateClasses.flyoutItemIsCurrent);

		// Hide all back-links
		this.$ctx
			.find(this._selectors.mainNavFlyoutList)
			.removeClass(this._stateClasses.flyoutListHasBacklink);

		// Set main column back to original X=0 position
		this.$flyoutMainColumn.css({ transform: 'translateX(0)' });
	},

	/**
	 * Applies and removes a style class to the last/deepest navigation column.
	 *
	 * @returns {undefined}
	 */
	markLastColumn() {
		const $lastColumn = this.$flyoutMainColumn
			.find(`.${this._stateClasses.flyoutItemIsCurrent}`)
			.last()
			.children(this._selectors.mainNavFlyoutColumn);

		// First, remove "last column"-styling from ALL columns,
		// i.e. main column as well as (sub-) flyout columns
		this.$flyoutMainColumn
			.find(`.${this._stateClasses.flyoutColumnIsLast}`)
			.add(this._selectors.mainNavFlyoutMainCol)
			.removeClass((this._stateClasses.flyoutColumnIsLast));

		// Apply "last column" styling either to main or to last flyout column
		if ($lastColumn.length === 0) {
			this.$flyoutMainColumn.addClass(this._stateClasses.flyoutColumnIsLast);
		} else {
			$lastColumn.addClass(this._stateClasses.flyoutColumnIsLast);
		}
	},

	clickFlyoutItem(ev) {
		const $targetListItem = $(ev.currentTarget).parent();
		const $siblings = $targetListItem.siblings();

		ev.preventDefault();

		// Remove "current" highlight state and hide all possible submenues
		// of self and sibling items
		$siblings
			.add($siblings.find(`.${this._stateClasses.flyoutItemIsCurrent}`))
			.removeClass(this._stateClasses.flyoutItemIsCurrent);

		$targetListItem.addClass(this._stateClasses.flyoutItemIsCurrent);
		this.moveMenu($targetListItem, 'forward');
		this.markLastColumn();
	},

	clickBackLink(ev) {
		const $target = $(ev.currentTarget);
		const $targetListItem = $target.parent();

		this.moveMenu($targetListItem, 'backward');
		this.markLastColumn();
	},

	/**
	 * Move menu left or right, depending on configured visible columns,
	 * while showing/hiding the back link on the leftmost column.
	 *
	 * @param {Object} $targetListItem jQuery Object of <li> the user clicked within
	 * @param {string} direction [forward|backward]
	 * @returns {undefined}
	 */
	moveMenu($targetListItem, direction) {
		// Number of visible columns. Needs to be aligned with CSS variable.
		// Minimum allowed value is 2.
		const visibleColumnsCount = 3;

		// Navigation nesting depth the user clicked
		const nestingLevel = $targetListItem.parents(this._selectors.mainNavFlyoutList).length + 1;

		if ((nestingLevel > visibleColumnsCount) && (direction === 'forward')) {
			// Move menu forward to an absolute position
			this.$flyoutMainColumn.css({ transform: `translateX(${(nestingLevel - visibleColumnsCount) * -100}%)` });

			// Show back link on leftmost visible column
			$targetListItem.parents(this._selectors.mainNavFlyoutList)
				.eq(visibleColumnsCount - 2)
				.addClass(this._stateClasses.flyoutListHasBacklink);

		} else if (direction === 'backward') {
			// Hide current back link
			$targetListItem.closest(this._selectors.mainNavFlyoutList)
				.removeClass(this._stateClasses.flyoutListHasBacklink);

			// Move menu backward to an absolute position
			this.$flyoutMainColumn.css({ transform: `translateX(${(nestingLevel - 3) * -100}%)` });

			// Remove "current" status from rightmost but one column,
			// thereby hiding visible columns further down the tree
			$targetListItem.siblings(`.${this._stateClasses.flyoutItemIsCurrent}`)
				.find(`.${this._stateClasses.flyoutItemIsCurrent}`)
				.last()
				.removeClass(this._stateClasses.flyoutItemIsCurrent);
		}
	},

	// Functionality for mobile viewports

	/**
	 * Toggles burger state as well as display of mobile menu.
	 *
	 * @param {Object} ev JS Event
	 * @returns {undefined}
	 */
	toggleMobileFlyout(ev) {
		const $el = $(ev.currentTarget);
		const targetSelector = this._selectors.mainNavMobileWrapper;

		if (toggle.isActive(this.$ctx, this._selectors.mainNavMobileTrigger)) {
			this.resetMobileCards();
			this.hideOverlay();
		} else {
			this.markLastCard();
			this.showOverlay();
		}

		toggle.toggleContent($el, targetSelector, this.$ctx, 'visibility');
	},

	/**
	 * Triggers all necessary actions upon item click.
	 *
	 * @param {Object} ev JS click event
	 * @returns {undefined}
	 */
	clickMobileCardItem(ev) {
		const $targetListItem = $(ev.currentTarget).parent();

		if ($targetListItem.hasClass(this._stateClasses.mobileListItemIsCurrent)) {
			// Navigate back to the clicked Level

			ev.preventDefault();

			// Expand all sibling and collapsed items down the tree
			$targetListItem.siblings()
				.removeClass(this._stateClasses.mobileListItemIsCollapsed);
			$targetListItem.find(`.${this._stateClasses.mobileListItemIsCollapsed}`)
				.removeClass(this._stateClasses.mobileListItemIsCollapsed);

			// Hide direct child card and all child cards down the tree
			$targetListItem.find(`.${this._stateClasses.mobileListItemIsCurrent}`)
				.add($targetListItem)
				.removeClass(this._stateClasses.mobileListItemIsCurrent);

			// Reset all vertical positions of child cards down the tree
			// @TODO find a better way to reset vertical position than removing ALL inline-styles
			$targetListItem.find(`${this._selectors.mainNavMobileFlyoutCard}[style]`).removeAttr('style');

			this.markLastCard();

		} else if ($targetListItem.hasClass(this._stateClasses.mobileListItemIsParent)) {
			// Open up clicked Level

			ev.preventDefault();

			// Collapse all other items
			$targetListItem.siblings().addClass(this._stateClasses.mobileListItemIsCollapsed);

			// Show direct child card
			$targetListItem.addClass(this._stateClasses.mobileListItemIsCurrent);

			// Move child card to correct position
			$targetListItem.children(this._selectors.mainNavMobileFlyoutCard)
				.css('top', $targetListItem.outerHeight());

			this.markLastCard();
		}
	},

	resetMobileCards() {
		// Expand all collapsed items
		this.$ctx.find(`.${this._stateClasses.mobileListItemIsCollapsed}`)
			.removeClass(this._stateClasses.mobileListItemIsCollapsed);

		// Hide all active (=is-current) cards
		this.$ctx.find(`.${this._stateClasses.mobileListItemIsCurrent}`)
			.removeClass(this._stateClasses.mobileListItemIsCurrent);

		// Reset all vertical positions of cards
		// @TODO find a better way to reset vertical position than removing ALL inline-styles
		this.$ctx.find(`${this._selectors.mainNavMobileFlyoutCard}[style]`)
			.removeAttr('style');
	},

	/**
	 * Applies and removes a style class to the last/deepest mobile navigation card,
	 * excluding the root card.
	 *
	 * @returns {undefined}
	 */
	markLastCard() {
		const $lastCard = this.$mobileFlyoutRootCard
			.find(`.${this._stateClasses.mobileListItemIsCurrent}`)
			.last()
			.children(this._selectors.mainNavMobileFlyoutCard);

		// First, remove "last column"-styling from ALL cards
		this.$mobileFlyoutRootCard
			.find(`.${this._stateClasses.mobileFlyoutCardIsLast}`)
			.removeClass((this._stateClasses.mobileFlyoutCardIsLast));

		if ($lastCard.length > 0) {
			$lastCard.addClass(this._stateClasses.mobileFlyoutCardIsLast);
		}
	},
});
