const requireAll = (requireContext) => {
	return requireContext.keys().map(requireContext);
};

// Get scripts from local terrific assets
requireAll(require.context('../assets/js', true, /^((?![\\/]node_modules|spec[\\/]).)*\.js$/)); // eslint-disable-line max-len

// Get scripts from local terrific components
requireAll(require.context('./', true, /^((?![\\/]node_modules|spec[\\/]).)*\.js$/));

// Extracted styles from local terrific assets
requireAll(require.context('../assets/css', true, /\/[a-z-]*\.scss$/));

// Extracted styles
requireAll(require.context('./', true, /css\/[a-z-]*\.scss$/));
