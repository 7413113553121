import $ from 'jquery';
import { Component, GondelBaseComponent } from '@gondel/core';

@Component('ClickContainer')
export default class ClickContainer extends GondelBaseComponent {
	attributes = {
		data: {
			link: 'js-link',
			blankWindow: 'js-blank-window',
		},
	};
	selectors = {
		linkElementSelector: '[data-js-link]',
	};
	_ctx: HTMLDivElement;
	$elements: any;
	$ctx: any;

	start(): void {
		this.$ctx = $(this._ctx);
		this.$elements = this.$ctx.find(this.selectors.linkElementSelector);
		this.$elements.click(this.handleClickEvent);
	}

	stop(): void {
		this.$elements.off('click');
	}

	handleClickEvent = (ev: MouseEvent): void => {
		const link = $(ev.currentTarget).data(this.attributes.data.link);
		const shouldOpenInNewWindow = this.$ctx.data(this.attributes.data.blankWindow);

		if (link !== '') {
			ev.preventDefault();
			window.open(link, shouldOpenInNewWindow !== undefined ? '_blank' : '_self');
		}
	};
}
