// @ts-check
import { endPoints } from '@dormakaba/ajax';
import $ from 'jquery';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';

/** @type {any} T - Global Terrific Namespace*/
const T = require('terrific');

/**
 * Basic Module description.
 */
T.Module.Minicart = T.createModule({
	_configs: {
	},
	_attributes: {
		data: {
			url: 'js-minicart-url',
			button: 'js-minicart-button',
		},
	},
	_selectors: {
		button: '[data-js-minicart-button]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.$button = this.$ctx.find(this._selectors.button);
		this.$button.on(this._eventNames.CLICK, this.loadLayer.bind(this));
		this.$quoteId = this.$button.data(this._attributes.data.button);

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	loadLayer(event) {
		event.preventDefault();

		endPoints.ekitTerrificHybrisController.getMiniCartContent.ajax({
			keys: {
				encodedContextPath: TerrificConfig.config.encodedContextPath,
				quoteId: this.$quoteId,
			},
		}).then((data) => {
			this._events.emit(T.EventName.Utils.LAYER_ONDEMAND, data);
		});
	},
});

