import $ from 'jquery';
import T from 'terrific';

/**
 * Header Module description.
 */
T.Module.Header = T.createModule({

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.isCurrentBreakpointXl = false;

		this._events.on('breakpointChange', (breakpoint) => {
			this.isCurrentBreakpointXl = Object.values(breakpoint).includes('xl');
		});

		this._events.on('mainOverlayShow', ({ scrollbarWidth }) => {
			if (this.isCurrentBreakpointXl) {
				this.toggleScrollbarCompensation(true, scrollbarWidth);
			}
		});

		this._events.on('mainOverlayHide', () => {
			if (this.isCurrentBreakpointXl) {
				this.toggleScrollbarCompensation(false);
			}
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	/**
	 * Apply or remove padding on the right to compensate scrollbar, thus
	 * preventing a jumping layout.
	 *
	 * @param {boolean} state true: add padding, false: reset padding
	 * @param {number} scrollbarWidth in pixels
	 * @returns {undefined}
	 */
	toggleScrollbarCompensation(state, scrollbarWidth) {
		if (state && scrollbarWidth) {
			this.$ctx.css('padding-right', `${scrollbarWidth}px`);
		} else {
			this.$ctx.css('padding-right', '');
		}
	},
});
