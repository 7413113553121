// @ts-check
import $ from 'jquery';
import { formatValue, deformatValue } from '../../../../assets/js/utils/currencyFormatter';

/** @type {any} T - Global Terrific Namespace*/
const T = require('terrific');

/**
 * Basic Module description.
 */
T.Module.CurrencyFormatterInput = T.createModule({
	_selectors: {
		itemPriceElement: '[data-js-offer-product-list-item-price-element]',
	},
	_eventNames: {
		CHANGE: 'change',
	},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$el = this.$ctx.find(this._selectors.itemPriceElement);

		// format input initially
		const formattedValue = formatValue(this.$el.val(), true);
		this.$el.val(formattedValue);

		// emit specific event on change
		this.$el.on(
			this._eventNames.CHANGE, this.onChange.bind(this)
		);

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	onChange(ev) {
		const $el = $(ev.target);
		const value = this.isValid($el) ? deformatValue($el.val().toString()) : deformatValue($el.prop('defaultValue'));

		const formattedValue = formatValue(value, true);
		$el.val(formattedValue);
		this._events.emit('currencyFormatterInputChanged');
	},

	isValid($el) {
		return $el.val() !== '' && $el.val() > 0 && $el.is(':valid');
	},
});
