/**
 * Registers a listener for a specific message event.
 * @param {string} code The code of the message to listen to.
 * @param {function} handler The function to be called when a message matching the code is sent. The first parameter
 * will be the data send with the message.
 * @returns {void} returns nothing.
 */
export function onMessage(code, handler) {
	document.addEventListener(`ekitSimpleMessaging.${code}`, (event: CustomEvent) => handler(event.detail));
}

/**
 * Broadcasts a message to all listeners listening to that specific message code this at this moment.
 * @param {string} code The code of the message.
 * @param {Object} data The data to be sent with the message, if any.
 * @returns {void} returns nothing.
 */
export function sendMessage(code, data) {
	const event = new CustomEvent(`ekitSimpleMessaging.${code}`, { bubbles: false, cancelable: false, detail: data });
	document.dispatchEvent(event);
}
