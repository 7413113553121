import { addNotification, MessageType } from '@dormakaba/notifications';
import { endPoints } from '@dormakaba/ajax';
import { toggleContent } from './toggle';

export const AbstractCart = {

	_commonSelectors: {
		updatePositionForm: '.js-cart-update-position-form',
		updatePositionInput: '[data-js-cart-update-position-input]',
		updateHeadPositionSalesTextInput: '[data-js-cart-update-position-sales-text]',
		updateHeadPositionSalesTextForm: '.js-cart-update-sales-text-form',
		configurableProductCopyButton: '[data-js-cart-copy-button]',
		savedItemListAddItemUrlInput: '#js-savedItemList-addItem-url',
		addOverlayCountSpan: '[js-add-overlay-entry-count]',
		headPositionCheckBox: '[data-js-edit-checkbox-emks-true]',
		singleEntryCheckBox: '[data-js-edit-checkbox-emks-false]',
		updateEntryQtyForm: '.js-entry-qty-update-form',
		updateEntryQtyInput: '[data-js-product-unit-conversion-quantity]',
		itemListDiv: '#js-item-listing-detail-div',
		addToListButton: '[data-js-add-to-list-btn]',
		addToListOverlay: '#js-add-to-list-overlay',
		addToListOverlayCopyButton: '[js-add-to-list-overlay-btn-copy]',
		addToListOverlayMoveButton: '[js-add-to-list-overlay-btn-move]',
		addToListOverlayDeleteFromSourceInput: '#js-add-to-list-overlay-input-delete-from-source',
		selectListOverlay: '#js-select-list-overlay',
		selectListOverlayDropdown: '#js-select-list-overlay-select',
		selectListOverlaySaveButton: '[data-js-select-list-overlay-btn-save]',
		selectListOverlayAddButton: '[js-select-list-overlay-btn-add]',
		saveListOverlay: '#js-save-list-overlay',
		saveListOverlaySharedButton: '[js-save-list-overlay-btn-privacy-shared-true]',
		saveListOverlayPrivateButton: '[js-save-list-overlay-btn-privacy-shared-false]',
		saveListOverlayPrivacyInput: '#js-save-list-overlay-input-privacy',
		saveListOverlayNameInput: '#js-save-list-overlay-input-name',
		saveListOverlayDescriptionInput: '#js-save-list-overlay-input-description',
		saveListOverlaySaveButton: '[data-js-save-list-overlay-btn-save]',
		saveListOverlaySaveUrlInput: '#js-savedItemList-save-url',
		removeItemOverlay: '#js-delete-items-overlay',
		updatePositionSalesOverlay: '#js-update-sales-text-overlay',
		removeItemOverlayConfirmButton: '[js-delete-items-overlay-btn-confirm]',
		updateSalesTextOverlayConfirmButton: '[js-update-sales-text-overlay-btn-confirm]',
		removeItemUrlInput: '#js-delete-items-url',
		updateSalesTextUrlInput: '#js-update-sales-text-url',
		directDeleteEntryButton: '[data-js-delete-entry-btn]',
		updateSalesTextButton: '[data-js-update-sales-text-btn]',
		directDeleteEntryEnableCheckbox: '[js-delete-entry-ip-enable]',
		directDeleteEntryEmksCheckbox: '[js-delete-entry-ip-emks]',
		directDeleteEntryValue: '#js-delete-entry-ip-value',
		containsNonPurchasableCheckbox: '[js-containsNonPurchasable-checkbox]',
		individualEntryActions: '[data-js-individual-entry-actions]',
		cartEditCheckBox: '[data-js-edit-checkbox-cart]',
		cartEditCheckBox2: '[data-js-edit-checkbox-wishlist]',
		moreLessAction: '[data-js-more-less-content-toggle-trigger]',
		salesTextOverlayInput: '#js-update-sales-text-overlay-input',
		headPositionCode: '#js-update-sales-text-head-position-code',
		toggleConfigurationInfo: '[data-js-more-less-config-info-toggle-trigger]',
		toggleCpqConfigurationInfo: '[data-js-more-less-cpq-config-info-toggle-trigger]',
		savedItemListToRemoveUrl: '#js-savedItemList-listToRemove-url',
		savedItemListUrl: '#js-savedItemList-default-url',
		savedItemListOverviewUrl: '#js-savedItemList-overview-url',
		savedItemListWarningDisplay: '#js-save-list-overlay-warning-display',
		savedItemListWarningMessage: '#js-save-list-overlay-warning-message',
	},

	_eventNames: {
		CLICK: 'click',
	},

	_stateClasses: {},

	_privacyValues: {
		SHARED: true,
		PRIVATE: false,
	},

	_privacyNames: {
		SHARED: 'SHARED',
		PRIVATE: 'PRIVATE',
	},

	_deleteFromSourceValues: {
		MOVE: true,
		COPY: false,
	},

	bindCommonEvents() {

		this.$ctx.on('submit', this._commonSelectors.updateHeadPositionSalesTextForm, this.onPositionFormSubmit.bind(this));
		this.$ctx.on('blur', this._commonSelectors.updateHeadPositionSalesTextInput, (event) => {
			const value = event.target.value;
			event.target.value = value || '';
			const $form = $(event.target).parents(this._commonSelectors.updateHeadPositionSalesTextForm);
			$form.submit();
		});

		this.$ctx.on('submit', this._commonSelectors.updatePositionForm, this.onPositionFormSubmit.bind(this));
		this.$ctx.on('blur', this._commonSelectors.updatePositionInput, (event) => {
			const value = event.target.value;
			event.target.value = value || '';
			const $form = $(event.target).parents(this._commonSelectors.updatePositionForm);
			$form.submit();
		});
		this.$ctx.on('click', this._commonSelectors.configurableProductCopyButton,
			this.onClickConfigurableProductCopyButton.bind(this));
		this.$ctx.on('click', this._selectors.priceSimulateButton, this.onClickPriceSimulation.bind(this));
		const refreshDetailsFn = this.refreshDetails.bind(this);
		this.$ctx.on('submit', this._commonSelectors.updateEntryQtyForm,
			this.onSavedItemListItemQtyUpdateFormSubmit.bind(this, refreshDetailsFn));
		this.$ctx.on('blur', this._commonSelectors.updateEntryQtyInput, (event) => {
			const $form = $(event.target).parents(this._commonSelectors.updateEntryQtyForm);
			$form.submit();
		});
		this.$ctx.on('click', this._commonSelectors.addToListButton, this.onClickAddToListButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.addToListOverlayCopyButton,
			this.onClickAddToListOverlayCopyOrMoveButton.bind(this, this._deleteFromSourceValues.COPY));
		this.$ctx.on('click', this._commonSelectors.addToListOverlayMoveButton,
			this.onClickAddToListOverlayCopyOrMoveButton.bind(this, this._deleteFromSourceValues.MOVE));
		this.$ctx.on('click', this._commonSelectors.selectListOverlaySaveButton,
			this.onClickSelectListOverlaySaveButton.bind(this));
		this.$ctx.on('change', this._commonSelectors.selectListOverlayDropdown,
			this.onChangeSelectListOverlayDropDown.bind(this));
		this.$ctx.on('click', this._commonSelectors.selectListOverlayAddButton,
			this.onClickSelectListOverlayAddButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.saveListOverlaySharedButton,
			this.onClickSaveListOverlayPrivacyButton.bind(this, this._privacyValues.SHARED));
		this.$ctx.on('click', this._commonSelectors.saveListOverlayPrivateButton,
			this.onClickSaveListOverlayPrivacyButton.bind(this, this._privacyValues.PRIVATE));
		this.$ctx.on('click', this._commonSelectors.saveListOverlaySaveButton,
			this.onClickSaveListOverlaySaveButton.bind(this));
		this.$ctx.on('click', this._selectors.deleteEntriesButton, this.onClickDeleteEntriesButton.bind(this));
		this.$ctx.on('blur', this._commonSelectors.saveListOverlayNameInput, this.controlSaveButton.bind(this));
		this.$ctx.on('blur', this._commonSelectors.saveListOverlayDescriptionInput, this.controlSaveButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.saveListOverlaySharedButton, this.controlSaveButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.saveListOverlayPrivateButton, this.controlSaveButton.bind(this));
		this.$ctx.on('change', this._selectors.checkBox, this.onClickCheckBoxEnableSaveButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.removeItemOverlayConfirmButton,
			this.onClickRemoveItemOverlayConfirmButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.updateSalesTextOverlayConfirmButton,
			this.onClickUpdatePositionSalesOverlayConfirmButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.directDeleteEntryButton,
			this.onClickDirectDeleteEntryButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.updateSalesTextButton,
			this.onClickUpdateSalesTextButton.bind(this));
		this.$ctx.on('click', this._commonSelectors.toggleConfigurationInfo,
			this.onClickCartConfigurationInfo.bind(this));
		this.$ctx.on('click', this._commonSelectors.toggleCpqConfigurationInfo,
			this.onClickCartCpqConfigurationInfo.bind(this));
	},

	onPositionFormSubmit(e) {
		e.preventDefault();
		const form = $(e.target);
		this.showLoadingSpinner();
		$.ajax({
			type: form.attr('method'),
			url: form.attr('action'),
			data: form.serialize(),
			success: () => {
				this.hideLoadingSpinner();
			},
			error: () => {
				this.hideLoadingSpinner();
			},
		});
	},

	onClickConfigurableProductCopyButton(ev) {
		let $target = $(ev.target);
		$target = $target.is(this._commonSelectors.configurableProductCopyButton) ?
			$target : $target.parents(this._commonSelectors.configurableProductCopyButton);
		const url = $target.attr('data-js-list-copy-url');
		this.showLoadingSpinner();
		$.ajax({
			url,
			type: 'GET',
			success: (response) => {
				let messageText = '';
				if (response === 'OK') {
					messageText = $('.js-copy-product-msg').data('js-copy-success-message');
					addNotification(MessageType.SUCCESS, messageText, messageText);
					this.refreshDetails();
				} else {
					messageText = $('.js-copy-product-msg').data('js-copy-failed-message');
					addNotification(MessageType.ERROR, messageText, messageText);
				}
				this.hideLoadingSpinner();
			},
			error: () => {
				/* eslint-disable no-console */
				console.log('Failed to copy the entry');
				/* eslint-enable no-console */
				this.hideLoadingSpinner();
			},
		});
	},
	hideCartEditCheckbox() {
		this.$ctx.find(this._commonSelectors.cartEditCheckBox).each(function () {
			$(this)
				.parent()
				.hide();
		});
		this.$ctx.find(this._commonSelectors.cartEditCheckBox2).each(function () {
			$(this)
				.parent()
				.hide();
		});
	},

	onClickPriceSimulation(ev) {
		const $target = $(ev.target);
		this.$ctx = $(this._ctx);
		const selectors = this._selectors;
		// prevent double clicks
		$target.unbind('click');
		const method = 'GET';
		const $simulateButton = this.$ctx.find(selectors.priceSimulateButton);
		const url = $simulateButton.attr('data-js-price-simulate-url');
		this.showLoadingSpinner();
		$.ajax({
			url,
			data: { isCheckout: false },
			type: method,
			dataType: 'html',
			success: (data) => {
				const responseData = `${data}`;
				$('#js-simulated-price-content').html(responseData);
				$simulateButton.prop('disabled', true);
				this.hideLoadingSpinner();
				this.hideCartEditCheckbox();
			},
			error: (error) => {
				/* eslint-disable no-console */
				console.log(`Failed to find the price Details => ${error}`);
				/* eslint-enable no-console */
				this.hideLoadingSpinner();
			},
		});
	},

	onClickCartConfigurationInfo(ev) {
		ev.stopImmediatePropagation();
		this.$ctx = $(this._ctx);
		const $target = $(ev.target);
		const targetContainer = $target.attr('data-js-more-less-config-info-toggle-target');
		// prevent double clicks
		$target.unbind('click');
		if ($(targetContainer).hasClass('config-info-not-loaded')) {
			const method = 'GET';
			const url = $target.attr('data-js-configuration-info-url');
			const headPosition = $target.attr('data-js-configuration-info-head-position-code');
			const entryId = $target.attr('data-js-configuration-info-cart-entry-id');
			this.showLoadingSpinner();
			$.ajax({
				url,
				data: { headPositionCode: headPosition, cartEntryId: entryId },
				type: method,
				dataType: 'html',
				success: (data) => {
					const responseData = `${data}`;
					$(targetContainer).html(responseData);
					this.hideLoadingSpinner();
				},
				error: (error) => {
					/* eslint-disable no-console */
					console.log(`Failed to load configuration info => ${error}`);
					/* eslint-enable no-console */
					const errorMessage = $target.attr('data-js-configuration-info-loading-error');
					$(targetContainer).html(errorMessage);
					this.hideLoadingSpinner();
				},
			});
			$(targetContainer).removeClass('config-info-not-loaded');
		}
		const $ctx = this.$ctx;
		toggleContent($target, targetContainer, $ctx);
	},

	onClickCartCpqConfigurationInfo(ev) {
		ev.stopImmediatePropagation();
		this.$ctx = $(this._ctx);
		const $target = $(ev.target);
		const targetContainer = $target.attr('data-js-more-less-cpq-config-info-toggle-target');
		// prevent double clicks
		$target.unbind('click');
		if ($(targetContainer).hasClass('config-info-not-loaded')) {
			const method = 'GET';
			const url = $target.attr('data-js-configuration-info-url');
			const cartCode = $target.attr('data-js-configuration-info-cart-code');
			const entryId = $target.attr('data-js-configuration-info-cart-entry-pk');
			this.showLoadingSpinner();
			$.ajax({
				url,
				data: { cartId: cartCode, cartEntryPk: entryId },
				type: method,
				dataType: 'html',
				success: (data) => {
					const responseData = `${data}`;
					$(targetContainer).html(responseData);
					this.hideLoadingSpinner();
				},
				error: (error) => {
					/* eslint-disable no-console */
					console.log(`Failed to load configuration info => ${error}`);
					/* eslint-enable no-console */
					const errorMessage = $target.attr('data-js-configuration-info-loading-error');
					$(targetContainer).html(errorMessage);
					this.hideLoadingSpinner();
				},
			});
			$(targetContainer).removeClass('config-info-not-loaded');
		}
		const $ctx = this.$ctx;
		toggleContent($target, targetContainer, $ctx);
	},

	onClickAddToListButton() {
		this.displayEntryCount();
		this.triggerFancyBoxOverlay(this._commonSelectors.addToListOverlay);
	},

	onClickAddToListOverlayCopyOrMoveButton(deleteFromSource, ev) {
		const $target = $(ev.target);
		$target.attr('disabled', 'disabled');
		$(this._commonSelectors.addToListOverlayDeleteFromSourceInput).val(deleteFromSource);
		this.loadSavedItemListSelectDropdownOptions();
		this.closeFancyBoxOverlay(this._commonSelectors.addToListOverlay);
		this.triggerFancyBoxOverlay(this._commonSelectors.selectListOverlay);
		setTimeout(() => { $target.removeAttr('disabled'); }, 1000);
	},

	onSavedItemListItemQtyUpdateFormSubmit(refreshDetailsFn, event) {
		event.preventDefault();
		const $form = $(event.target);
		if (!$form.find('.m-product-unit-conversion__input').is(':invalid')) {
			const url = $form.attr('action');
			const method = $form.attr('method');
			this.showLoadingSpinner();
			$.ajax({
				url,
				data: $form.serialize(),
				type: method,
				success: (response) => {
					let messageText = '';
					if (response === 'OK') {
						this.hideLoadingSpinner();
						refreshDetailsFn();
					} else if (response === 'PARTIALLY_OK') {
						this.hideLoadingSpinner();
						messageText = $('.js-saved-item-list-update-quantity-msg').data('js-update-partial-message');
						addNotification(MessageType.WARNING, 'vanguard.common.notifications.exceedOrderQuantity', messageText);
						refreshDetailsFn();
					} else {
						this.hideLoadingSpinner();
						messageText = $('.js-saved-item-list-update-quantity-msg').data('js-update-error-message');
						addNotification(MessageType.ERROR, 'vanguard.cart.quantityChanged.error', messageText);
					}
				},
				error: () => {
					this.hideLoadingSpinner();
				},
			});
		}
	},

	triggerFancyBoxOverlay(selector) {
		const fancyBoxContentParent = this.$ctx.find(selector);
		this._events.emit(T.EventName.Utils.LAYER_ONDEMAND, fancyBoxContentParent);
	},

	closeFancyBoxOverlay(selector) {
		$(selector).find('[data-fancybox-close]').click();
	},

	getCSRFToken() {
		return $('#js-CSRFToken').val();
	},

	isEditBtnClicked() {
		const selectors = this._selectors;
		const editBtn = this.$ctx.find(selectors.editButton);
		return (editBtn.attr('style') === 'display: none;');
	},

	displayEntryCount() {
		const selectors = this._selectors;
		const selectedOnly = this.isEditBtnClicked();
		let count = 0;
		if (selectedOnly) {
			count = this.$ctx.find(selectors.checkBox).filter(':checked').length;
		} else {
			count = this.$ctx.find(selectors.checkBox).length;
		}
		this.$ctx.find(this._commonSelectors.addOverlayCountSpan).text(count);
	},

	bindClickMoreLessToggle() {
		this.$ctx.on('click', this._commonSelectors.moreLessAction, (ev) => {
			ev.stopImmediatePropagation();
			const $ctx = this.$ctx;
			const $el = $(ev.currentTarget);
			const target = $el.attr('data-js-more-less-content-toggle-target');

			toggleContent($el, target, $ctx);
		});
	},

	hideElementsOnRefresh() {
		const selectors = this._selectors;
		this.$ctx.find(selectors.cancelButton).hide();
		this.$ctx.find(selectors.deleteEntriesButton).hide();
		this.$ctx.find(selectors.checkBox).each(function () {
			$(this)
				.parent()
				.hide();
		});
		this.controlElementsOnInvalidItems();
	},

	hideIndividualEntryActions() {
		$(this._commonSelectors.individualEntryActions).wrap('<div style="display: none"></div>');
	},

	showIndividualEntryActions() {
		$(this._commonSelectors.individualEntryActions).unwrap();
	},

	refreshDetails() {
		const url = $(this._commonSelectors.itemListDiv).attr('data-js-get-details-url');
		const method = 'GET';
		this.showLoadingSpinner();
		$.ajax({
			url,
			type: method,
			success: (htmlResp) => {
				$(this._commonSelectors.itemListDiv).html(htmlResp);
				this.hideElementsOnRefresh();
				this.hideLoadingSpinner();
				this.bindClickMoreLessToggle();
			},
			error: () => {
				/* eslint-disable no-console */
				console.log('Failed to refresh details');
				/* eslint-enable no-console */
				this.hideLoadingSpinner();
			},
		});
	},

	refreshMiniCart() {
		endPoints.ekitTerrificHybrisController.getCartSubTotal.clearCache();
		this._events.emit('cartUpdated');
		if (typeof window.CustomEvent === 'function') {
			document.dispatchEvent(new CustomEvent('refreshMiniCart'));
		}
	},

	isTrue(deleteFromSource) {
		return deleteFromSource && deleteFromSource !== 'false';
	},

	filterSelectListOverlayDropdown() {
		$(this._commonSelectors.selectListOverlayDropdown).val('-1');
		const options = $(`${this._commonSelectors.selectListOverlayDropdown} option[data-js-shared]`);
		$.map(options, (element) => {
			if ($(element).attr('data-js-shared') === 'true') {
				$(element).append(' (shared)').text();
			} else {
				$(element).append(' (private)').text();
			}
		});
	},

	onChangeSelectListOverlayDropDown() {
		if ($(this._commonSelectors.selectListOverlayDropdown).val() !== '-1') {
			$(this._commonSelectors.selectListOverlaySaveButton).removeAttr('disabled');
		} else {
			$(this._commonSelectors.selectListOverlaySaveButton).attr('disabled', 'disabled');
		}
	},

	onClickSelectListOverlaySaveButton(ev) {
		const $target = $(ev.target);
		$target.attr('disabled', 'disabled');
		const targetCode = $(this._commonSelectors.selectListOverlayDropdown).val();
		const deleteFromSource = $(this._commonSelectors.addToListOverlayDeleteFromSourceInput).val();
		this.doAddItems(deleteFromSource, targetCode);
		this.closeFancyBoxOverlay(this._commonSelectors.selectListOverlay);
		setTimeout(() => { $target.removeAttr('disabled'); }, 1000);
	},

	clearSaveListOverlay() {
		$(this._commonSelectors.saveListOverlayNameInput).val('');
		$(this._commonSelectors.saveListOverlayDescriptionInput).val('');
		$(this._commonSelectors.saveListOverlaySharedButton).prop('checked', true);
		$(this._commonSelectors.saveListOverlayPrivateButton).prop('checked', false);
	},

	onClickSelectListOverlayAddButton() {
		this.clearSaveListOverlay();
		this.getSavedItemListToRemove(this._privacyNames.SHARED);
		this.closeFancyBoxOverlay(this._commonSelectors.selectListOverlay);
		this.triggerFancyBoxOverlay(this._commonSelectors.saveListOverlay);
	},

	loadSavedItemListSelectDropdownOptions() {
		const url = $(this._commonSelectors.selectListOverlayDropdown).attr('data-js-url');
		$.ajax({
			url,
			type: 'GET',
			success: (htmlResp) => {
				if (htmlResp) {
					$(this._commonSelectors.selectListOverlayDropdown).html(htmlResp);
					this.filterSelectListOverlayDropdown();
				}
			},
			error: () => {
				/* eslint-disable no-console */
				console.log('Failed to refresh details');
				/* eslint-enable no-console */
			},
		});
	},

	onClickSaveListOverlayPrivacyButton(privacyValue) {
		if (privacyValue === this._privacyValues.SHARED) {
			$(this._commonSelectors.saveListOverlaySharedButton).prop('checked', true);
			$(this._commonSelectors.saveListOverlayPrivateButton).prop('checked', false);
			this.getSavedItemListToRemove(this._privacyNames.SHARED);
		} else if (privacyValue === this._privacyValues.PRIVATE) {
			$(this._commonSelectors.saveListOverlaySharedButton).prop('checked', false);
			$(this._commonSelectors.saveListOverlayPrivateButton).prop('checked', true);
			this.getSavedItemListToRemove(this._privacyNames.PRIVATE);
		}
		$(this._commonSelectors.saveListOverlayPrivacyInput).val(privacyValue === this._privacyValues.SHARED);
	},

	onClickDeleteEntriesButton() {
		$(this._commonSelectors.directDeleteEntryEnableCheckbox).prop('checked', false);
		this.triggerFancyBoxOverlay(this._commonSelectors.removeItemOverlay);
	},

	onClickDirectDeleteEntryButton(ev) {
		let $target = $(ev.target);
		// prevent double clicks
		$target = $target.is(this._commonSelectors.directDeleteEntryButton) ? $target :
			$target.parents(this._commonSelectors.directDeleteEntryButton);
		$(this._commonSelectors.directDeleteEntryEnableCheckbox).prop('checked', true);
		$(this._commonSelectors.directDeleteEntryEmksCheckbox).prop('checked', $target.data('js-is-emks'));
		$(this._commonSelectors.directDeleteEntryValue).val($target.data('js-value'));
		this.triggerFancyBoxOverlay(this._commonSelectors.removeItemOverlay);
	},

	onClickUpdateSalesTextButton(ev) {
		/* eslint-disable no-console */
		console.log('onClickUpdateSalesTextButton');
		const $target = $(ev.target);
		// prevent double clicks
		$target.unbind('click');
		$(this._commonSelectors.salesTextOverlayInput).val($target.data('js-sales-text-value'));
		$(this._commonSelectors.headPositionCode).val($target.data('js-head-position-code'));
		this.triggerFancyBoxOverlay(this._commonSelectors.updatePositionSalesOverlay);
	},

	getSavedItemListMergeDataRequest(targetCode, sourceCode) {
		this.$ctx = $(this._ctx);
		const entryNumbers = [];
		const headPositions = [];
		let $singleEntryCheckBox = this.$ctx.find(this._commonSelectors.singleEntryCheckBox);
		let $headPositionCheckBox = this.$ctx.find(this._commonSelectors.headPositionCheckBox);
		if (this.isEditBtnClicked()) {
			$singleEntryCheckBox = this.$ctx.find(this._commonSelectors.singleEntryCheckBox).filter(':checked');
			$headPositionCheckBox = this.$ctx.find(this._commonSelectors.headPositionCheckBox).filter(':checked');
		}
		$singleEntryCheckBox.each(function () {
			entryNumbers.push(parseFloat($(this).val()));
		});
		$headPositionCheckBox.each(function () {
			headPositions.push($(this).val());
		});

		return {
			sourceCode,
			targetCode,
			entryNumbers,
			headPositions,
			CSRFToken: this.getCSRFToken(),
		};
	},
	/* eslint-disable no-console */
	controlSaveButton(ev) {
		if (($(this._commonSelectors.saveListOverlaySharedButton).hasClass('a-button--b-8')
			&& $(this._commonSelectors.saveListOverlayPrivateButton).hasClass('a-button--b-8'))) {
			$(this._commonSelectors.saveListOverlaySaveButton).attr('disabled', 'disabled');
		} else {
			$(this._commonSelectors.saveListOverlaySaveButton).removeAttr('disabled');
		}
		const $target = ev.target;
		let value = $target.value;
		ev.target.value = value;
	},
	/* eslint-enable no-console */
	onClickEditButton() {
		const selectors = this._selectors;
		const cancelBtn = this.$ctx.find(selectors.cancelButton);
		const checkbox = this.$ctx.find(selectors.checkBox);
		const editBtn = this.$ctx.find(selectors.editButton);
		const deleteEntriesBtn = this.$ctx.find(selectors.deleteEntriesButton);
		const priceSimulateBtn = this.$ctx.find(selectors.priceSimulateButton);
		checkbox.each(function () {
			$(this)
				.parent()
				.show();
		});
		$(cancelBtn).show();
		$(cancelBtn).removeAttr('hidden');
		$(deleteEntriesBtn).show();
		$(editBtn).hide();
		$(priceSimulateBtn).hide();

		this.hideIndividualEntryActions();
		$(this._commonSelectors.addToListButton).attr('disabled', 'disabled');
		$(selectors.deleteEntriesButton).attr('disabled', 'disabled');
		checkbox.each(function () {
			$(this).prop('checked', false);
		});
	},

	onClickCancelButton() {
		const selectors = this._selectors;
		const cancelBtn = this.$ctx.find(selectors.cancelButton);
		const checkbox = this.$ctx.find(selectors.checkBox);
		const editBtn = this.$ctx.find(selectors.editButton);
		const deleteEntriesBtn = this.$ctx.find(selectors.deleteEntriesButton);
		const priceSimulateBtn = this.$ctx.find(selectors.priceSimulateButton);

		checkbox.each(function () {
			$(this)
				.parent()
				.hide();
		});
		$(cancelBtn).hide();
		$(deleteEntriesBtn).hide();
		$(editBtn).show();
		$(priceSimulateBtn).show();

		this.showIndividualEntryActions();
		$(this._commonSelectors.addToListButton).removeAttr('disabled');
		$(selectors.deleteEntriesButton).removeAttr('disabled');
		this.controlElementsOnInvalidItems();
	},

	onClickSaveListOverlaySaveButton(ev) {
		const $target = $(ev.target);
		$target.attr('disabled', 'disabled');
		this.$ctx = $(this._ctx);
		const requestData = {
			name: $(this._commonSelectors.saveListOverlayNameInput).val(),
			description: $(this._commonSelectors.saveListOverlayDescriptionInput).val(),
			publicAccess: $(this._commonSelectors.saveListOverlayPrivacyInput).val(),
			CSRFToken: this.getCSRFToken(),
		};
		this.showLoadingSpinner();
		$.ajax({
			url: $(this._commonSelectors.saveListOverlaySaveUrlInput).val(),
			type: 'POST',
			data: requestData,
			traditional: true,
			success: (response) => {
				const deleteFromSource = $(this._commonSelectors.addToListOverlayDeleteFromSourceInput).val();
				this.deleteOldestSavedItemList();
				this.doAddItems(deleteFromSource, response.code);
			},
			error: () => {
				/* eslint-disable no-console */
				console.log('Save new list failed');
				/* eslint-enable no-console */
				this.hideLoadingSpinner();
			},
		});
		this.closeFancyBoxOverlay(this._commonSelectors.saveListOverlay);
		setTimeout(() => {
			$target.removeAttr('disabled');
		}, 1000);
	},
	getSavedItemListToRemove(wishlistPrivacy) {
		const requestData = {
			accessType: wishlistPrivacy,
		};
		const warningDisplay = document.querySelector(this._commonSelectors.savedItemListWarningDisplay);
		const warningMessage = document.querySelector(this._commonSelectors.savedItemListWarningMessage);
		const oldMessage = 	$(warningMessage).data('translation');
		return new Promise((resolve, reject) => {
			$.ajax({
				url: $(this._commonSelectors.savedItemListToRemoveUrl).val(),
				type: 'GET',
				data: requestData,
				traditional: true,
				success: (response) => {
					if (response.code) {
						$(warningMessage).text(oldMessage.replace('{0}', response.name).replace('{1}', response.modificationDate));
						$(warningDisplay).show();
						resolve(response.code);
					} else {
						$(warningDisplay).hide();
						resolve(null);
					}
				},
				error: () => {
					reject(new Error('Get saved item list to remove failed'));
				},
			});
		});
	},
};
