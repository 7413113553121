import T from 'terrific';
import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.SearchSortingSelect = T.createModule({
	_selectors: {
		sorting: 'select[data-js-search-sorting-select]',
	},
	_data: {
		preselectedSorting: 'js-search-sorting-selection-selected-sorting',
	},
	_eventNames: {
		CHANGE: 'change',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		this.$sortingSelect = this.$ctx.find(this._selectors.sorting);
		this.preselectedSorting = this.$ctx.data(this._data.preselectedSorting);

		if (this.preselectedSorting) {
			this.$sortingSelect.val(this.preselectedSorting);
		}

		this.$sortingSelect.on(this._eventNames.CHANGE, this.changeSorting.bind(this));

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	changeSorting(ev) {
		const sorting = ev.currentTarget.value;
		const url = this.$ctx.find(`option[value=${sorting}]`).data('url');
		window.location = url;
	},
});
