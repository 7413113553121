import T from 'terrific';
import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.ProductResults = T.createModule({
	_attributes: {
		productUrl: 'data-js-product-url',
	},
	_selectors: {
		listItemMobile: '[data-js-mobile-list-item]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {},

	start(resolve) {
		this.$ctx = $(this._ctx);

		this._events.on('breakpointChange', (breakpoint) => {
			this.isListItemClickActive = Object.values(breakpoint).includes('xs');
		});

		this.$ctx.find(this._selectors.listItemMobile)
			.on(this._eventNames.CLICK, (ev) => {
				ev.stopPropagation();

				if (this.isListItemClickActive) {
					this.onListItemMobileClick(ev);
				}
			});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	onListItemMobileClick(ev) {
		const $el = $(ev.currentTarget);
		const productUrl = $el.attr(this._attributes.productUrl);

		window.location.assign(productUrl);
	},
});
