import T from 'terrific';
import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.CountryRegionSelection = T.createModule({
	_selectors: {
		countrySelect: 'select[data-js-country-select]',
		regionSelect: 'select[data-js-region-select]',
		additionalAddressSelect: 'select[data-js-additional-address-select]',
		copyAddressSelect: 'select[data-js-additional-address-copy-select]',
		addressNameOne: '[data-js-address-name-one]',
		addressNameTwo: '[data-js-address-name-two]',
		addressNameThree: '[data-js-address-name-three]',
		addressNameFour: '[data-js-address-name-four]',
		addressStreet: '[data-js-address-street-label]',
		addressHouseNumber: '[data-js-address-house-number]',
		addressPostalCode: '[data-js-address-postal-code]',
		addressCity: '[data-js-address-city]',
	},
	_data: {
		preselectedCountryIso: 'js-country-region-selection-selected-country',
		preselectedRegionIso: 'js-country-region-selection-selected-region',
		preselectedAdditionalAddress: 'js-additional-address-selection-selected',
		existingAddress: 'js-available-address-list',
	},
	_eventNames: {
		CHANGE: 'change',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		this.$countrySelect = this.$ctx.find(this._selectors.countrySelect);
		this.$regionSelect = this.$ctx.find(this._selectors.regionSelect);
		this.$copyAddressSelect = this.$ctx.find(this._selectors.copyAddressSelect);
		this.$addressNameOne = this.$ctx.find(this._selectors.addressNameOne);
		this.$addressNameTwo = this.$ctx.find(this._selectors.addressNameTwo);
		this.$addressNameThree = this.$ctx.find(this._selectors.addressNameThree);
		this.$addressNameFour = this.$ctx.find(this._selectors.addressNameFour);
		this.$addressStreet = this.$ctx.find(this._selectors.addressStreet);
		this.$addressHouseNumber = this.$ctx.find(this._selectors.addressHouseNumber);
		this.$addressPostalCode = this.$ctx.find(this._selectors.addressPostalCode);
		this.$addressCity = this.$ctx.find(this._selectors.addressCity);
		this.$additionalAddressSelect = this.$ctx.find(this._selectors.additionalAddressSelect);
		this.countryRegionsMap = JSON.parse(this._ctx.querySelector('script').innerHTML);
		this.preselectedCountryIso = this.$ctx.data(this._data.preselectedCountryIso);
		this.preselectedRegionIso = this.$ctx.data(this._data.preselectedRegionIso);
		this.existingAddress = this.$ctx.data(this._data.existingAddress);

		if (this.preselectedCountryIso) {
			this.$countrySelect.val(this.preselectedCountryIso);

			// if we have a pre selected Country, we have to initialize the regions,
			// and consider a pre selected region as well.
			this.initializeRegionSelect();
		}
		if (this.preselectedAdditionalAddress) {
			this.$additionalAddressSelect.val(this.preselectedAdditionalAddress);
		}

		this.$countrySelect.on(this._eventNames.CHANGE, this.updateRegionSelect.bind(this));
		this.$copyAddressSelect.on(this._eventNames.CHANGE, this.updateAddress.bind(this));
		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	getFilteredRegions(countryIso) {
		return this.countryRegionsMap[countryIso];
	},

	initializeRegionSelect() {
		const $regions = $(this.getFilteredRegions(this.preselectedCountryIso));

		$regions.each((index, region) => {
			this.$regionSelect.append(`<option value="${region.isoCode}">${region.name}</option>`);
		});

		if (this.preselectedRegionIso) {
			this.$regionSelect.val(this.preselectedRegionIso);
		}

		this.$regionSelect.attr('disabled', false);
	},

	updateRegionSelect(ev) {
		const iso = ev.currentTarget.value;
		// Remove all options except first one ('Please select')
		this.$regionSelect.find('option:gt(0)').remove();
		this.fillRegionSelectOptions(iso);
	},

	updateAddress(eve) {
		const data = eve.target.value.split(',');
		this.$addressNameOne.val(data[1]);
		this.$addressNameTwo.val(data[2]);
		this.$addressNameThree.val(data[3]);
		this.$addressNameFour.val(data[4]);
		this.$addressStreet.val(data[5]);
		this.$addressHouseNumber.val(data[6]);
		this.$addressPostalCode.val(data[7]);
		this.$addressCity.val(data[8]);
		this.$countrySelect.val(data[9]);
		const regions = $(this.getFilteredRegions(data[9]));
		let localIndex = 0;
		if (regions.length > 0) {
			this.$regionSelect.attr('disabled', false);
			regions.each((index, region) => {
				this.$regionSelect.append(`<option value="${region.isoCode}">${region.name}</option>`);
				if (region.isoCode === data[10].trim()) {
					localIndex = index;
				}
			});
			this.$regionSelect.prop('selectedIndex', localIndex + 1);
		} else {
			this.$regionSelect.attr('disabled', true);
			this.$regionSelect.prop('selectedIndex', 0);
		}
	},

	fillRegionSelectOptions(iso) {
		const $regions = $(this.getFilteredRegions(iso));

		if ($regions.length > 0) {
			this.$regionSelect.attr('disabled', false);

			$regions.each((index, region) => {
				this.$regionSelect.append(`<option value="${region.isoCode}">${region.name}</option>`);
			});
		} else {
			this.$regionSelect.attr('disabled', true);
			this.$regionSelect.prop('selectedIndex', 0);
		}
	},
});
