/* eslint-disable complexity */
// @ts-check
import { endPoints } from '@dormakaba/ajax';
import { addNotification, MessageType } from '@dormakaba/notifications';
import doT from 'dot';
import $ from 'jquery';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';
/** @type {any} T - Global Terrific Namespace*/
const T = require('terrific');

/**
 * Basic Module description.
 */
T.Module.QuickOrder = T.createModule({
	_attributes: {
		data: {
			productExistsInFormMsg: 'js-quick-order-product-exists-in-form-msg',
			minRows: 'js-quick-order-min-rows',
			maxRows: 'js-quick-order-max-rows',
		},
	},
	_selectors: {
		quickOrderTBody: '[data-js-quick-order-tbody]',
		emptyQuickOrderRowTemplate: '[data-js-empty-quick-order-row-template]',
		filledQuickOrderRowTemplate: '[data-js-filled-quick-order-row-template]',
		quickOrderAddToCartButton: '[data-js-quick-order-add-to-cart-button]',
		quickOrderResetFormButton: '[data-js-quick-order-reset-form-button]',
		addButtonIdPrefix: '#quick-order-add-button',
		disabledButton: '[data-js-add-to-cart-button-enable-button]',
		productIdInputIdPrefix: '#quick-order-product-id-input',
		quickOrderRowIdPrefix: '#quick-order-row',
		deleteButtonIdPrefix: '#quick-order-delete-button',
		quantityInputIdPrefix: '#quantity_quick-order-input',
		errorOutputIdPrefix: '#error-quick-order-output',
		updateEntryQtyInput: '[data-js-product-unit-conversion-quantity]',
	},
	_eventNames: {
		KEYDOWN: 'keydown',
		CLICK: 'click',
		CHANGE: 'change',
		BLUR: 'blur',
	},
	_stateClasses: {
		errorClass: 'a-input--error',
		displayNoneClass: 'h-d-none',
		displayBlockClass: 'h-d-block',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.$quickOrderTBody = this.$ctx.find(this._selectors.quickOrderTBody);
		this.$emptyQuickOrderRowTemplate = this.$ctx.find(this._selectors.emptyQuickOrderRowTemplate);
		this.$filledQuickOrderRowTemplate = this.$ctx.find(this._selectors.filledQuickOrderRowTemplate);
		this.rows = [];
		this.minRows = this.$quickOrderTBody.data(this._attributes.data.minRows);
		this.maxRows = this.$quickOrderTBody.data(this._attributes.data.maxRows);
		this.productAlreadyExistsMessage = this.$quickOrderTBody.data(this._attributes.data.productExistsInFormMsg);
		this.$ctx.on(
			this._eventNames.CLICK, this._selectors.quickOrderAddToCartButton, this.submitAddToCart.bind(this)
		);
		this.$ctx.on(
			this._eventNames.CLICK, this._selectors.quickOrderResetFormButton, this.clearQuickOrderForm.bind(this)
		);
		this.$ctx.on(this._eventNames.BLUR, this._selectors.updateEntryQtyInput, (event) => {
			const $input = $(event.target);
			let messageText = '';
			if (isNaN($input.val())) {
				$input.val($input.prop('min'));
				messageText = $('.js-quick-order-update-quantity-msg').data('js-update-error-message');
				addNotification(MessageType.ERROR, 'vanguard.cart.quantityChanged.error', messageText);
				this.disableAddToCartButtons(false);
			} else if (!isNaN($input.prop('min')) && (Number($input.val()) < Number($input.prop('min')))) {
				$input.val($input.prop('min'));
				messageText = $('.js-quick-order-update-quantity-msg').data('js-update-partial-message');
				addNotification(MessageType.WARNING, 'vanguard.common.notifications.exceedOrderQuantity', messageText);
				this.disableAddToCartButtons(false);
			} else if (!isNaN($input.prop('max')) && (Number($input.val()) > Number($input.prop('max')))) {
				$input.val($input.prop('max'));
				messageText = $('.js-quick-order-update-quantity-msg').data('js-update-partial-message');
				addNotification(MessageType.WARNING, 'vanguard.common.notifications.exceedOrderQuantity', messageText);
				this.disableAddToCartButtons(false);
			}
		});
		this.renderInitialEmptyRows();
		this.disableAddToCartButtons(false);

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	/**
		* Creates new empty row with given id.
		* @param {number} id: id of empty row
		* @returns {void} void
	*/
	addEmptyQuickOrderRow(id) {
		const row = this.createNewRowTemplate(this.$emptyQuickOrderRowTemplate, id);
		this.$quickOrderTBody.append(row);
		this.rows.push({
			'id': id,
			'productId': '',
			'displayCode': '',
			'filled': false,
		});

		this.addEventListenerToProductIdInput(id, '');
		this.addEventListenerToAddButton(id);
	},

	/**
		* Adds eventlistener to button of quickOrder row.
		* @param {number} id: id of empty row
		* @returns {void} void
	*/
	addEventListenerToAddButton(id) {
		const $quickOrderAddButton = this.$quickOrderTBody.find(`${this._selectors.addButtonIdPrefix}-${id}`);
		$quickOrderAddButton.on(this._eventNames.CLICK, this.triggerFetchOnButtonClick.bind(this, id));
	},

	/**
		* Adds eventlistener to input of quickOrder row.
		* @param {number} id: id of empty row
		* @param {string} displayCode: displayCode of product
		* @returns {void} void
	*/
	addEventListenerToProductIdInput(id, displayCode = '') {
		const $productIdInput = this.$quickOrderTBody.find(`${this._selectors.productIdInputIdPrefix}-${id}`);
		$productIdInput.on(this._eventNames.KEYDOWN, this.handleProductIdInputChange.bind(this, id, displayCode));
	},

	/**
		* Adds empty row on bottom of quickOrder form.
		* @returns {void} void
	*/
	addLastEmptyRow() {
		const filledRowsAmount = this.rows.filter((row) => row.filled === true).length;
		const emptyRowsAmount = this.rows.filter((row) => row.filled === false).length;

		if (filledRowsAmount > (this.minRows - 1) && filledRowsAmount < this.maxRows && emptyRowsAmount === 0) {
			const newId = this.findAvailableIdInArray();
			this.addEmptyQuickOrderRow(newId);
		}
	},

	/**
	 	* Puts the focus on the article field on the next empty row
	 	* @returns {void} void
	 */
	focusOnEmptyRow() {
		const firstEmptyRow = this.rows.find((row) => row.filled === false);
		if (firstEmptyRow) {
			const $quickOrderRow = this.$quickOrderTBody.find(`${this._selectors.quickOrderRowIdPrefix}-${firstEmptyRow.id}`);
			const $productIdInput = $quickOrderRow.find(`${this._selectors.productIdInputIdPrefix}-${firstEmptyRow.id}`);
			$productIdInput.focus();
		}
	},

	/**
		* Checks if given product id is already included in rows array.
		* @param {string} code: code of product that should be checked
		* @returns {boolean} Boolean
	*/
	checkIfProductAlreadyInRows(code) {
		const checkFilter = (row) => (
			(row.productId === code)
			&& row.filled
		);

		return this.rows.filter(checkFilter).length > 0;
	},

	/**
		* Clears quickOrder form.
		* @returns {void} void
	*/
	clearQuickOrderForm() {
		this.$quickOrderTBody.empty();
		this.rows = [];
		this.renderInitialEmptyRows();
	},

	/**
		* Creates template for new quickOrder row.
		* @param {Object} template: dot template of a single quickOrder row
		* @param {number} id: id of new quickOrder row
		* @param {Object} data: data to fill the new quickOrder row
		* @param {Object} priceInformationData: price data to fill the new quickOrder row
		* @returns {string} dot template for new quickOrder row
	*/
	createNewRowTemplate(template, id, data, priceInformationData) {
		return doT.template(template.html())({ id, data, priceInformationData });
	},

	/**
		 * Handles a quantity keydown event
		 * @param {Event} ev: event of changing quantity
		 * @returns {void} void
	 */
	handleQuantityKeydown(ev) {
		const key = ev.charCode ? ev.charCode : ev.keyCode ? ev.keyCode : 0; // eslint-disable-line
		if (key === 13) {
			this.focusOnEmptyRow();
		}
	},

	/**
		* Deletes quickOrder rows with zero quantity.
		* @param {number} id: id of row that should be deleted
		* @param {Event} ev: event of changing quantity
		* @returns {void} void
	*/
	deleteProductWithZeroQuantity(id, ev) {
		if ($(ev.target).val() < 1) {
			this.deleteQuickOrderRow(id);
		}
	},

	/**
		* Deletes quickOrder row.
		* @param {number} id: id of row that should be deleted
		* @param {Event} ev: event of delete action
		* @returns {void} void
	*/
	deleteQuickOrderRow(id, ev) {
		if (ev) {
			ev.preventDefault();
		}
		const $quickOrderRow = this.$quickOrderTBody.find(`${this._selectors.quickOrderRowIdPrefix}-${id}`);

		if (this.rows.length > this.minRows) {
			const row = this.rows.filter((r) => r.id === id)[0];
			this.rows.splice(this.rows.indexOf(row), 1);
			$quickOrderRow.remove();
		} else {
			const row = this.createNewRowTemplate(this.$emptyQuickOrderRowTemplate, id);
			this.replaceQuickOrderRow($quickOrderRow, row);
			const arrayIndex = this.rows.indexOf(this.rows.filter((r) => r.id === id)[0]);
			this.rows[arrayIndex] = {
				'id': id,
				'productId': '',
				'displayCode': '',
				'filled': false,
			};

			this.addEventListenerToProductIdInput(id, '');
			this.addEventListenerToAddButton(id);
		}
	},

	/**
		* Enable/disable addToCartButton
		* @param {boolean} bool: is true
		* @returns {void}: void
	*/
	disableAddToCartButtons(bool = true) {
		this.$ctx.find(this._selectors.disabledButton).each((index, el) => {
			$(el).prop('disabled', bool);
		});
	},

	escapeHtmlCustom(id) {
		return id
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;');
	},

	/**
		* Fetches data from products.
		* @param {number} id: id of row that has changed
		* @param {string} value: value of the input field
		* @returns {void} void
	*/
	fetchProductInfo(id, value) {
		const productId = this.escapeHtmlCustom(value);
		const productAlreadyinRows = this.checkIfProductAlreadyInRows(productId);

		const addButton = this.$quickOrderTBody.find(`${this._selectors.addButtonIdPrefix}-${id}`);
		addButton.attr('disabled', true);

		if (productAlreadyinRows) {
			this.printErrorMessage(id, this.productAlreadyExistsMessage);
			addButton.attr('disabled', false);
			return;
		}

		if (productId) {
			endPoints.ekitTerrificHybrisController.getQuickOrderProductInfo.ajax({
				keys: {
					encodedContextPath: TerrificConfig.config.encodedContextPath,
					productCode: productId,
				},
			}).then((data) => {
				if (data.productData) {
					this.fillQuickOrderRow(id, data.productData, data.priceInformationData);
				} else {
					this.printErrorMessage(id, data.errorMsg);
				}
				if (data.analyticsDataLayer) {
					window.dataLayer.push(data.analyticsDataLayer);
				}
				addButton.attr('disabled', false);
			});
		} else {
			this.deleteQuickOrderRow(id);
		}
	},


	/**
		* Fills row with fetched data.
		* @param {number} id: id of row that should be filled
		* @param {Object} data: data that should be filled into row
		* @param {Object} priceInformationData: price data that should be filled into row
		* @returns {void} void
	*/
	fillQuickOrderRow(id, data, priceInformationData) {
		const productAlreadyinRows = this.checkIfProductAlreadyInRows(data.code);
		if (productAlreadyinRows) {
			this.printErrorMessage(id, this.productAlreadyExistsMessage);
			return;
		}
		// add new row to TBody
		const row = this.createNewRowTemplate(this.$filledQuickOrderRowTemplate, id, data, priceInformationData);
		const $quickOrderRow = this.$quickOrderTBody.find(`${this._selectors.quickOrderRowIdPrefix}-${id}`);
		this.replaceQuickOrderRow($quickOrderRow, row);

		// fill object in row-array
		const arrayIndex = this.rows.indexOf(this.rows.filter((r) => r.id === id)[0]);
		this.rows[arrayIndex] = {
			'id': id,
			'productId': data.code,
			'displayCode': data.displayCode,
			'displayUnitCode': data.unit.code,
			'filled': true,
		};

		// add event listeners
		this.addEventListenerToProductIdInput(id, data.displayCode);

		const $quickOrderDeleteButton = this.$quickOrderTBody.find(`${this._selectors.deleteButtonIdPrefix}-${id}`);
		$quickOrderDeleteButton.on(this._eventNames.CLICK, this.deleteQuickOrderRow.bind(this, id));

		const $productUnitConversion = this.$quickOrderTBody.find(`${this._selectors.quantityInputIdPrefix}-${id}`);
		$productUnitConversion.on(this._eventNames.CHANGE, (ev) => {
			this.$ctx.find(this._selectors.disabledButton).each((index, el) => {
				$(el).prop('disabled', false);
			});
			if ($(ev.target).is(':invalid')) {
				this.$ctx.find(this._selectors.disabledButton).each((index, el) => {
					$(el).prop('disabled', true);
				});
			}
		});
		$productUnitConversion.on(this._eventNames.KEYDOWN, this.handleQuantityKeydown.bind(this));
		$productUnitConversion.select();
		this._sandbox.addModules(this._ctx);

		// create ruling empty row (if more than minRows filled lines)
		this.addLastEmptyRow();
	},

	/**
		* Finding available ID in rowArray.
		* @returns {string} newId
	*/
	findAvailableIdInArray() {
		let newId = this.rows.length;
		this.rows.sort(this.sortRowArray);
		$(this.rows).each((index, row) => {
			const nextId = this.rows[index + 1] ? this.rows[index + 1].id : this.rows.length;
			if (row.id + 1 !== nextId) {
				newId = row.id + 1;
				return false;
			}
		});
		return newId;
	},

	/**
		* Creates data object of all quickOrder rows.
		* @returns {Object | null} JSON data object
	*/
	getJsonDataFromRowArray() {
		const dataJsonArray = [];

		this.rows.forEach((el) => {
			if (el.filled) {
				const $row = this.$quickOrderTBody.find(`${this._selectors.quickOrderRowIdPrefix}-${el.id}`);
				const $qtyField = $($row).find(`${this._selectors.quantityInputIdPrefix}-${el.id}`);
				if (el.productId && $qtyField.val()) {
					dataJsonArray.push({
						'product': {
							'code': el.productId,
						},
						'displayUnitQuantity': Number($qtyField.val()),
						'displayUnit': {
							'code': el.displayUnitCode,
						},
					});
				}
			}
		});

		const dataObject = { 'cartEntries': dataJsonArray };
		return dataJsonArray.length === 0 ? null : dataObject;
	},

	/**
		* Handles changing of the quickOrder rows input.
		* @param {number} id: id of row that changed
		* @param {string} displayCode: displayCode of product
		* @param {KeyboardEvent} ev: event of changing the input
		* @returns {void} void
	*/
	handleProductIdInputChange(id, displayCode, ev) {
		const value = $(ev.target).val();
		if (value === '' || value !== displayCode) {
			const key = ev.charCode ? ev.charCode : ev.keyCode ? ev.keyCode : 0; // eslint-disable-line
			this.triggerFetchOnInputChange(id, key, value);
		}
	},

	/**
		* Displays the error message of invalid quickOrder rows.
		* @param {number} id: id of row that is invalid
		* @param {string} message: error message to display
		* @returns {void} void
	*/
	printErrorMessage(id, message) {
		const $errorInputField = this.$quickOrderTBody.find(`${this._selectors.productIdInputIdPrefix}-${id}`);
		const $errorOutput = this.$quickOrderTBody.find(`${this._selectors.errorOutputIdPrefix}-${id}`);

		if (message === '') {
			$errorInputField.removeClass(this._stateClasses.errorClass);

			$errorOutput.removeClass(this._stateClasses.displayBlockClass);
			$errorOutput.addClass(this._stateClasses.displayNoneClass);
		} else {
			$errorInputField.addClass(this._stateClasses.errorClass);

			$errorOutput.addClass(this._stateClasses.displayBlockClass);
			$errorOutput.removeClass(this._stateClasses.displayNoneClass);
		}

		$errorOutput.text(message);
	},

	/**
		* Triggers rendering of initial rows.
		* @returns {void} void
	*/
	renderInitialEmptyRows() {
		for (let i = 0; i < this.minRows; i++) {
			this.addEmptyQuickOrderRow(i);
		}
	},

	/**
		* Replaces old row with new row.
		* @param {Object} $currentRow: JQuery object of old row
		* @param {string} newRow: template of new row
		* @returns {void} void
	*/
	replaceQuickOrderRow($currentRow, newRow) {
		$currentRow.replaceWith(newRow);
	},

	/**
		* Sorts quickOrder rows.
		* @param {Object} a: row a
		* @param {Object} b: row b
		* @returns {number} number for sort function
	*/
	sortRowArray(a, b) {
		if (a.id < b.id) {
			return -1;
		}
		if (a.id > b.id) {
			return 1;
		}
		return 0;
	},

	/**
		* Submits quickOrder.
		* @param {Event} ev: event of clicking the add to cart button
		* @returns {void} void
	*/
	submitAddToCart(ev) {
		ev.preventDefault();
		this.disableAddToCartButtons(true);
		const dataObject = this.getJsonDataFromRowArray();
		if (dataObject) {
			endPoints.ekitTerrificHybrisController.postQuickOrderAddToCart.ajax({
				keys: {
					encodedContextPath: TerrificConfig.config.encodedContextPath,
					CSRFToken: TerrificConfig.config.CSRFToken,
				},
				body: dataObject,
			}).then((data) => {
				let messageType;
				switch (data.type) {
					case 'SUCCESS':
						messageType = MessageType.SUCCESS;
						break;
					case 'WARNING':
						messageType = MessageType.WARNING;
						break;
					case 'ERROR':
						messageType = MessageType.ERROR;
						break;
					default:
						messageType = MessageType.WARNING;
				}
				addNotification(messageType, data.type.toLowerCase(), data.message);
				this.clearQuickOrderForm();
				this._events.emit('cartUpdated');
				this.disableAddToCartButtons(false);
				if (typeof window.CustomEvent === 'function') {
					document.dispatchEvent(new CustomEvent('refreshMiniCart'));
				}

				if (data.analyticsDataLayer) {
					window.dataLayer.push(data.analyticsDataLayer);
				}
			});
		}
	},

	/**
		* Trigger for handling button click.
		* @param {number} id: id of row that changed
		* @returns {void} void
	*/
	triggerFetchOnButtonClick(id) {
		const $productIdInput = this.$quickOrderTBody.find(`${this._selectors.productIdInputIdPrefix}-${id}`);
		const value = $productIdInput.val();
		if (value !== '') {
			this.fetchProductInfo(id, value);
		}
	},

	/**
		* Trigger for handling input change.
		* @param {number} id: id of row that changed
		* @param {number} key: char of used key
		* @param {string} value: value of the input field
		* @returns {void} void
	*/
	triggerFetchOnInputChange(id, key, value) {
		if (key === 13) {
			this.fetchProductInfo(id, value);
		}
	},
});

export default T.Module.QuickOrder;
