import { Component, GondelBaseComponent } from '@gondel/core';
import flatpickr from 'flatpickr';
import $ from 'jquery';
import moment from 'moment';
import TerrificConfig from '../../../../../terrific/assets/js/configs/terrific-config/terrific-config';

@Component('Datepicker')
export default class Datepicker extends GondelBaseComponent {
	attributes: {
		data: {
			datePickerMinDate: string;
			datePickerMaxDate: string;
			datePickerLocale: string;
			datePickerEnableTime: string;
			defaultDate: string;
		};
	};
	configs: {
		datePickerConfig: {
			allowInput: Boolean;
			minuteIncrement: Number;
			altInput: Boolean;
			disableMobile: Boolean;
			onChange: Function;
			onClose: Function;
			mode: string;
		};
	};
	handleChange: (dates: Array<Date>) => void;
	handleOnClose: (dates: Array<Date>) => void;

	start() {
		this.attributes = {
			data: {
				datePickerMinDate: 'js-datepicker-min-date',
				datePickerMaxDate: 'js-datepicker-max-date',
				datePickerLocale: 'js-datepicker-locale',
				datePickerEnableTime: 'js-datepicker-enable-time',
				defaultDate: 'js-datepicker-default-date',
			},
		};
		this.configs = {
			datePickerConfig: {
				allowInput: true,
				minuteIncrement: 1,
				altInput: true,
				disableMobile: true,
				onChange: () => {},
				onClose: () => {},
				mode: 'js-datepicker-mode',
			},
		};

		const $ctx = $(this._ctx);
		const config = Object.assign({}, this.configs.datePickerConfig);
		this.setupPicker($ctx, config);
	}

	setupPicker($picker, config) {
		$picker.attr('autocomplete', 'off');
		return Promise.all([
			import('flatpickr'),
			this.updateDatePickerConfig($picker, config),
			this.localizeDatePicker($picker, config),
		]).then(() => {
			const instance: any = $picker.flatpickr ? $picker.flatpickr(config) : flatpickr(this._ctx, config);
			if (instance && instance.config) {
				instance.config.onChange.push(this.handleChange);
				instance.config.onClose.push(this.handleOnClose);
			}
		});
	}

	updateDatePickerConfig($picker, config) {
		const time = Boolean($picker.data(this.attributes.data.datePickerEnableTime));
		const locale = $picker.data(this.attributes.data.datePickerLocale);
		const localeFormat = locale.replace(/_/g, '-');
		let time12hr = false;

		if (time) {
			const localizedString = new Date().toLocaleString(localeFormat).toLowerCase();
			time12hr = localizedString.includes('pm') || localizedString.includes('am');
		}

		config.onReady = arr => {
			if (arr[0]) {
				this.updateDateInputValue($picker, arr[0], time);
			}
		};
		config.formatDate = date => {
			const dateFormat = TerrificConfig.config.dateFormat.replace('dd', 'DD').replace('yyyy', 'YYYY');
			return time ? moment(date).format(`${dateFormat} HH:mm:ss`) : moment(date).format(dateFormat);
		};
		config.onValueUpdate = arr => {
			this.updateDateInputValue($picker, arr[0], time);
		};
		config.time_24hr = !time12hr;
		config.enableTime = time;
		config.minDate = $picker.data(this.attributes.data.datePickerMinDate);
		config.maxDate = $picker.data(this.attributes.data.datePickerMaxDate);
		config.mode = $picker.data(this.configs.datePickerConfig.mode)
			? $picker.data(this.configs.datePickerConfig.mode)
			: 'single';
		config.defaultDate = $picker.data(this.attributes.data.defaultDate);
		if ($picker.attr('id') === 'preferredDeliveryDate') {
			config.disable = [
				function(date) {
					return date.getDay() === 0 || date.getDay() === 6;
				},
			];
		}
	}

	updateDateInputValue($picker, date, time) {
		const format = time ? 'Y-m-d H:i' : 'Y-m-d';
		$picker.val(flatpickr.formatDate(date, format));
	}

	localizeDatePicker($picker, config) {
		const locale = $picker.data(this.attributes.data.datePickerLocale);
		const localeImportPromise = import(`flatpickr/dist/l10n/${locale}.js`)
			.then(module => {
				return module.default[locale];
			})
			.catch(() => {
				// Return english as fallback:
				return import('flatpickr/dist/l10n/default.js').then(module => module.english);
			});
		return localeImportPromise.then(locale => {
			config.locale = locale;
		});
	}
}
