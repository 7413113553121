import T from 'terrific';
import $ from 'jquery';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';

/**
 * Price Visiblity molecule. Used to toggle the visibility of prices on the site.
 */
T.Module.PriceVisibility = T.createModule({
	_selectors: {
		visibilityToggle: '[data-js-price-visibility-toggle]',

	},
	_stateClasses: {
		contentVisibleClass: 'state-price-content-visible',
	},
	_storageItems: {
		visibilityToggle: 'price-visibility-toggle',
	},
	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);

		if (!TerrificConfig.config.priceVisibilityEnabled) {
			return;
		}

		const visibilityToggleItem = this._storageItems.visibilityToggle;
		const bodyClass = this._stateClasses.contentVisibleClass;
		const visibilityToggleSelector = this._selectors.visibilityToggle;
		if (typeof localStorage.getItem(visibilityToggleItem) === 'undefined') {
			localStorage.setItem(visibilityToggleItem, false);
		} else {
			// Invert value for initial toggle
			localStorage.setItem(visibilityToggleItem, !(localStorage.getItem(visibilityToggleItem) === 'true'));
			// Set visibility
			this.toggleVisibility(visibilityToggleItem, bodyClass, visibilityToggleSelector);
		}
		$(visibilityToggleSelector)
			.click(() => {
				this.toggleVisibility(visibilityToggleItem, bodyClass, visibilityToggleSelector);
			});
		resolve();
	},
	toggleVisibility(visibilityToggleItem, bodyClass, visibilityToggleSelector) {
		localStorage.setItem(visibilityToggleItem, !(localStorage.getItem(visibilityToggleItem) === 'true'));
		let content;
		if (!(localStorage.getItem(visibilityToggleItem) === 'true')) {
			$('body').addClass(bodyClass);
			content = $(visibilityToggleSelector).data('toggle-show');
		} else {
			$('body').removeClass(bodyClass);
			content = $(visibilityToggleSelector).data('toggle-hide');
		}
		$(visibilityToggleSelector).html(content);
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},
});

