import T from 'terrific';
import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.GlobalMessage = T.createModule({
	_eventNames: {
		CLICK: 'click',
		ANIMATIONEND: 'animationend',
		CLOSE_MESSAGE: 'closeGlobalMessage',
	},
	_stateClasses: {
		messageContainerIsHidden: 'm-global-message__container--is-hidden',
	},
	_selectors: {
		closeButton: '[data-js-global-message-close-button]',
		messageContainer: '.m-global-message__container',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		this.$ctx.on(this._eventNames.CLICK, this._selectors.closeButton, this.hideComponent.bind(this));

		this._events.on(this._eventNames.CLOSE_MESSAGE, () => {
			this.$ctx.find(this._selectors.messageContainer).remove();
		});
		resolve();
	},

	hideComponent(event) {
		const $messageContainer = $(event.currentTarget).closest(this._selectors.messageContainer);
		$messageContainer.addClass(this._stateClasses.messageContainerIsHidden);
		$messageContainer.on(this._eventNames.ANIMATIONEND, () => {
			$messageContainer.remove();
		});
	},
});
