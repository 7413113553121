import T from 'terrific';
import $ from 'jquery';
import { addNotification, MessageType } from '@dormakaba/notifications';
import { AbstractCart } from '../../../../assets/js/utils/abstract-cart';

/**
 * Basic Module description.
 */
T.Module.WishList = T.createModule(
	$.extend(
		AbstractCart,
		{
			_selectors: {
				editButton: '[data-js-edit-btn-wishlist]',
				cancelButton: '[data-js-cancel-edit-btn-wishlist]',
				checkBox: '[data-js-edit-checkbox-wishlist]',
				deleteEntriesButton: '[data-js-delete-entries-wishlist]',
				priceSimulateButton: '[data-js-price-simulate-button-wishlist]',
				priceSimulateButtonAsync: '[data-js-price-simulate-button-wishlist-async]',
				entryPriceSimulateButton: '[data-js-entry-price-simulate-button-wishlist]',
				currentSavedItemListCodeInput: '#js-savedItemList-code',
				addToCartButton: '[data-js-list-add-to-cart-btn]',
				addToListButton: '[data-js-add-to-list-btn]',
				addToCartOverlay: '#js-list-add-to-cart-overlay',
				addToCartOverlayCopyButton: '[js-add-to-cart-overlay-btn-copy]',
				addToCartOverlayMoveButton: '[js-add-to-cart-overlay-btn-move]',
				containsInvalidConfigurationCheckbox: '[js-containsInvalidConfiguration-checkbox]',
			},

			start(resolve) {
				// bind ctx
				this.$ctx = $(document);
				const selectors = this._selectors;
				this.hideElementsOnRefresh();

				this.bindCommonEvents();

				this.$ctx.on('click', selectors.editButton, this.onClickEditButtonForWishlist.bind(this));
				this.$ctx.on('click', selectors.cancelButton, this.onClickCancelButtonForWishlist.bind(this));
				this.$ctx.on('click', selectors.addToCartButton, this.onClickAddToCartButton.bind(this));
				this.$ctx.on('click', selectors.priceSimulateButtonAsync, this.onClickPriceSimulationAsync.bind(this));
				this.$ctx.on('click', selectors.entryPriceSimulateButton, this.onClickEntryPriceSimulation.bind(this));
				this.$ctx.on('click', selectors.addToCartOverlayCopyButton,
					this.onClickAddToCartOverlayCopyOrMoveButton.bind(this, this._deleteFromSourceValues.COPY));
				this.$ctx.on('click', selectors.addToCartOverlayMoveButton,
					this.onClickAddToCartOverlayCopyOrMoveButton.bind(this, this._deleteFromSourceValues.MOVE));

				resolve();
			},

			onClickPriceSimulationAsync(ev) {
				const $target = $(ev.target);
				this.$ctx = $(this._ctx);
				const selectors = this._selectors;
				// prevent double clicks
				$target.unbind('click');
				const method = 'GET';
				const $simulateButton = this.$ctx.find(selectors.priceSimulateButtonAsync);
				const $editButton = this.$ctx.find(selectors.editButton);
				const $cancelButton = this.$ctx.find(selectors.cancelButton);
				const $addToCartButton = this.$ctx.find(selectors.addToCartButton);
				const $addToListButton = this.$ctx.find(selectors.addToListButton);
				const url = $simulateButton.attr('data-js-price-simulate-url-async');
				$simulateButton.prop('disabled', true);
				$editButton.prop('disabled', true);
				$cancelButton.prop('disabled', true);
				$addToCartButton.prop('disabled', true);
				$addToListButton.prop('disabled', true);
				this.showLoadingSpinner();
				$.ajax({
					url,
					data: { isCheckout: false },
					type: method,
					dataType: 'html',
					success: (data) => {
						const responseData = `${data}`;
						$('#js-simulated-price-content').html(responseData);
						this.hideLoadingSpinner();
					},
					error: (error) => {
						/* eslint-disable no-console */
						console.log(`Failed to find the price Details => ${error}`);
						this.hideLoadingSpinner();
						/* eslint-enable no-console */
					},
				});
			},

			onClickEntryPriceSimulation(ev) {
				const $target = $(ev.target);
				const selectors = this._selectors;
				// prevent double clicks
				$target.unbind('click');
				const method = 'GET';
				const url = $target.data('js-entry-price-simulate-url');
				const entryDivId = $target.data('js-entry-price-simulate-div-id');
				$(entryDivId).addClass('disable-div');
				$target.attr('disabled', 'disabled');
				const $simulateButton = this.$ctx.find(selectors.priceSimulateButtonAsync);
				const oldSimulateButtonState = $simulateButton.prop('disabled');
				$simulateButton.prop('disabled', true);
				$.ajax({
					url,
					data: { isCheckout: false },
					type: method,
					dataType: 'html',
					success: (data) => {
						const responseData = `${data}`;
						$('#js-simulated-price-content').html(responseData);
						$simulateButton.prop('disabled', oldSimulateButtonState);
						this.hideElementsOnRefresh();
					},
					error: (error) => {
						/* eslint-disable no-console */
						console.log(`Failed to find the price Details => ${error}`);
						this.hideLoadingSpinner();
						/* eslint-enable no-console */
						$(entryDivId).removeClass('disable-div');
						$simulateButton.prop('disabled', oldSimulateButtonState);
						this.hideElementsOnRefresh();
					},
				});
			},

			onClickEditButtonForWishlist() {
				this.onClickEditButton();
				$(this._selectors.addToCartButton).attr('disabled', 'disabled');
			},

			onClickCancelButtonForWishlist() {
				this.onClickCancelButton();

				const hasInvalidConfiguration = $('[data-js-invalidconfig="true"]').length > 0;
				const hasNonPurchasable = $('[data-js-purchasable="false"]').length > 0;
				if (!hasNonPurchasable && !hasInvalidConfiguration) {
					$(this._selectors.addToCartButton).removeAttr('disabled');
				}
			},

			onClickAddToCartButton() {
				this.displayEntryCount();
				this.triggerFancyBoxOverlay(this._selectors.addToCartOverlay);
			},

			onClickAddToCartOverlayCopyOrMoveButton(deleteFromSource, ev) {
				const $target = $(ev.target);
				$target.attr('disabled', 'disabled');
				this.doAddItems(deleteFromSource, '');
				this.closeFancyBoxOverlay(this._selectors.addToCartOverlay);
				setTimeout(() => { $target.removeAttr('disabled'); }, 1000);
			},

			getCartId() {
				return this.$ctx.find(this._selectors.currentSavedItemListCodeInput).val();
			},

			controlElementsOnInvalidItems() {
				const selectors = this._selectors;
				const ctx = this.$ctx;
				if (ctx.find(this._commonSelectors.containsNonPurchasableCheckbox).prop('checked') ||
					ctx.find(selectors.containsInvalidConfigurationCheckbox).prop('checked')) {
					ctx.find(selectors.addToCartButton).attr('disabled', 'disabled');
				}
			},

			displayItemAddNotification(targetCode, deleteFromSource) {
				let messageCode = '';
				if (!targetCode || targetCode === '') {
					if (this.isTrue(deleteFromSource)) {
						messageCode = 'wishlist.notification.cart.move.success';
					} else {
						messageCode = 'wishlist.notification.cart.copy.success';
					}
				} else if (this.isTrue(deleteFromSource)) {
					messageCode = 'wishlist.notification.wishlist.move.success';
				} else {
					messageCode = 'wishlist.notification.wishlist.copy.success';
				}
				addNotification(MessageType.SUCCESS, messageCode, $(this._commonSelectors.addToListOverlay).data(messageCode));
			},

			doAddItems(deleteFromSource, targetCode) {
				this.$ctx = $(this._ctx);
				const savedItemListAddItemUrlInputSelector = this._commonSelectors.savedItemListAddItemUrlInput;
				const mergeData = this.getSavedItemListMergeDataRequest(targetCode, this.getCartId());
				const url =
					`${this.$ctx.find(savedItemListAddItemUrlInputSelector).val()}?deleteFromSource=${deleteFromSource}`;
				this.showLoadingSpinner();
				$.ajax({
					url,
					type: 'POST',
					data: mergeData,
					traditional: true,
					success: (response) => {
						this.hideLoadingSpinner();
						if (response === 'OK') {
							this.refreshDetails();
							this.displayItemAddNotification(targetCode, deleteFromSource);
						} else if (response === 'NOT_ACCEPTABLE') {
							const message = 'cart.notification.wishlist.copy.or.move.failed';
							addNotification(MessageType.ERROR, message, $(this._commonSelectors.addToListOverlay).data(message));
						}
						this.refreshMiniCart();
					},
					error: (xhr, textStatus, errorThrown) => {
						/* eslint-disable no-console */
						console.log('Adding failed, details below \n-----------START-----------');
						console.log(`xhr => ${xhr}`);
						console.log(`textStatus => ${textStatus}`);
						console.log(`errorThrown => ${errorThrown}`);
						console.log('----------END----------');
						/* eslint-enable no-console */
						this.hideLoadingSpinner();
					},
				});
			},

			onClickCheckBoxEnableSaveButton() {
				const selectors = this._selectors;
				if ($('[data-js-edit-checkbox-wishlist]:checked').length > 0) {
					const hasNonPurchasable = $('[data-js-purchasable="false"]:checked').length > 0;
					const hasInvalidConfiguration = $('[data-js-invalidconfig="true"]:checked').length > 0;
					$(this._commonSelectors.addToListButton).removeAttr('disabled');
					if (hasNonPurchasable || hasInvalidConfiguration) {
						$(selectors.addToCartButton).attr('disabled', 'disabled');
					} else {
						$(selectors.addToCartButton).removeAttr('disabled');
					}
					$(selectors.deleteEntriesButton).removeAttr('disabled');
				} else {
					$(this._commonSelectors.addToListButton).attr('disabled', 'disabled');
					$(selectors.addToCartButton).attr('disabled', 'disabled');
					$(selectors.deleteEntriesButton).attr('disabled', 'disabled');
				}
			},

			onClickRemoveItemOverlayConfirmButton() {
				this.$ctx = $(this._ctx);
				let requestData = {};
				/* eslint-disable spaced-comment */
				if ($(this._commonSelectors.directDeleteEntryEnableCheckbox).prop('checked')) {
					//For deleting entry directly
					const isEmks = $(this._commonSelectors.directDeleteEntryEmksCheckbox).prop('checked');
					const value = [$(this._commonSelectors.directDeleteEntryValue).val()];
					requestData = {
						cartId: $('#js-savedItemList-code').val(),
						entryNumbers: isEmks ? [] : value,
						headPositions: isEmks ? value : [],
						CSRFToken: this.getCSRFToken(),
					};

				} else {
					//For Deleting entry from edit
					const mergeData = this.getSavedItemListMergeDataRequest('', this.getCartId());
					requestData = {
						cartId: mergeData.sourceCode,
						headPositions: mergeData.headPositions,
						entryNumbers: mergeData.entryNumbers,
						CSRFToken: mergeData.CSRFToken,
					};
				}
				this.showLoadingSpinner();
				/* eslint-enable spaced-comment */
				$.ajax({
					url: $(this._commonSelectors.removeItemUrlInput).val(),
					type: 'POST',
					data: requestData,
					traditional: true,
					success: () => {
						this.refreshDetails();
						this.hideLoadingSpinner();
					},
					error: () => {
						/* eslint-disable no-console */
						console.log('Delete item failed.');
						/* eslint-enable no-console */
						this.hideLoadingSpinner();
					},
				});
				this.closeFancyBoxOverlay(this._commonSelectors.removeItemOverlay);
			},

			emptyFunction() {
				// Do nothing.
			},

			sync() {
				// Called when start() method of all registered modules was called.
			},

			showLoadingSpinner() {
				$('#loadingSpinner').css({ display: 'block' });
			},

			hideLoadingSpinner() {
				$('#loadingSpinner').css({ display: 'none' });
			},
		}
	)
);
