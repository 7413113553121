import T from 'terrific';
import $ from 'jquery';
import { endPoints } from '@dormakaba/ajax';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';

/**
 * Basic Module description.
 */
T.Module.OfferQuoteSelection = T.createModule({
	_attributes: {
		data: {
			projectId: 'js-offer-quote-selection-select-project-id',
		},
	},
	_selectors: {
		quoteSelect: '[data-js-offer-quote-selection-select]',
		quoteInformationContainer: '[data-js-offer-quote-selection-information-container]',
		quoteInformationContainerPrint: '[data-js-offer-quote-selection-information-container-print]',
		projectId: '[data-js-offer-quote-selection-select-project-id]',
	},
	_eventNames: {
		CHANGE: 'change',
	},
	_stateClasses: {
		printInvisibleClass: 'h-d-print-none',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		this.$quoteSelect = this.$ctx.find(this._selectors.quoteSelect);

		this.$quoteSelect.on(this._eventNames.CHANGE, this.getQuoteContent.bind(this));

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	getQuoteContent(ev) {
		const quoteId = ev.target.value;
		const projectId = this.$ctx.find(this._selectors.projectId).data(this._attributes.data.projectId);

		endPoints.ekitTerrificHybrisController.getQuoteContent.ajax({
			keys: {
				encodedContextPath: TerrificConfig.config.encodedContextPath,
				quoteId,
				projectId,
			},
		}).then((data) => {
			this.replaceDynamicQuoteInformation(data.infoLayer);
			this._events.emit('replaceDynamicProductList', data.offerListLayer);
		});
	},

	replaceDynamicQuoteInformation(layer) {
		const $quoteInformationContainer = this.$ctx.find(this._selectors.quoteInformationContainer);
		const $quoteInformationContainerPrint = this.$ctx.find(this._selectors.quoteInformationContainerPrint);

		$quoteInformationContainer.empty();
		$quoteInformationContainerPrint.empty();
		$quoteInformationContainer.append(layer);
		$quoteInformationContainerPrint.append(layer);
	},
});
