// @ts-check
import { endPoints } from '@dormakaba/ajax';
import $ from 'jquery';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';

/** @type {any} T - Global Terrific Namespace*/
const T = require('terrific');

/**
 * Basic Module description.
 */
T.Module.CartBadge = T.createModule({
	_selectors: {
		cartBadgeField: '[data-js-cart-badge-field]',
	},
	_stateClasses: {
		animatedClass: 'a-cart-badge__span--animated',
		hiddenClass: 'h-d-none',
	},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$cartBadge = $(this.$ctx.find(this._selectors.cartBadgeField));

		const isCartEmpty = parseInt(this.$cartBadge.text(), 10) <= 0 || !this.$cartBadge.text();
		this.$cartBadge.toggleClass(this._stateClasses.hiddenClass, isCartEmpty);

		this._events.on('cartUpdated', () => {
			// Connect to backend endpoint
			this.fetchCartAmount();
		});

		endPoints.ekitTerrificHybrisController.getCartSubTotal.onCacheClear(() => {
			this.fetchCartAmount();
		});

		// fetch cart amount on page load because header is cached.
		this.fetchCartAmount(false);
		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	setCartBadgeValue(amount, animate = true) {
		const isCartEmpty = amount <= 0 || !amount;
		this.$cartBadge.toggleClass(this._stateClasses.hiddenClass, isCartEmpty);

		if (animate) {
			this.$cartBadge.removeClass(this._stateClasses.animatedClass);
			this.$cartBadge.outerWidth();
			this.$cartBadge.addClass(this._stateClasses.animatedClass);
		}

		this.$cartBadge.text(amount);
	},

	fetchCartAmount(animate = true) {
		endPoints.ekitTerrificHybrisController.getCartSubTotal
			.ajax({
				keys: {
					encodedContextPath: TerrificConfig.config.encodedContextPath,
				},
			}).catch((e) => {
				// eslint-disable-next-line no-console
				console.warn('Promise error fetchCartAmount:', e);
			})
			.then((result) => {
				const sum = (result) ? result.miniCartCountSum : 0;
				this.setCartBadgeValue(sum, animate);
				return result;
			});
	},
});
