/**
 * This file contains the default fancybox configuration based on the current one ui config
 *
 * Usage:
 * import { getFancyboxConfig } from '../../configs/fancybox/fancybox';
 * console.log(getFancyboxConfig());
 */
import OneUi from '../one-ui-config/one-ui-config';
// Require HTML Templates using the html-loader
const arrowLeftButton = <string>require('./arrowLeftButton.html');
const arrowRightButton = <string>require('./arrowRightButton.html');
const baseTpl = <string>require('./baseTpl.html');
const closeButton = <string>require('./closeButton.html');
const smallButton = <string>require('./smallButton.html');

/**
 * Expect that the following interface will be provided by oneui:
 */
export type OneUiFancyBoxSettings = {
	i18n: {
		close: string;
		next: string;
		prev: string;
		error: string;
	};
};

type FancyboxConfigOptions = {
	width: 'full' | 'content';
	modal: boolean;
};

/**
 * Return the default fancybox configuration
 */
export function getFancyboxConfig(options: Partial<FancyboxConfigOptions> = {}) {
	const oneUiConfig = OneUi.config;

	/**
	 * Css classes to be inserted into baseTpl, based on the options param.
	 */
	const additionalCssClasses = [];
	const fancyboxWidth = options.width === undefined ? 'content' : options.width;
	additionalCssClasses.push(`fancybox-${fancyboxWidth}-width`);

	/**
	 * Structure must be same as: http://fancyapps.com/fancybox/#docs
	 */
	const config = {
		/**
		 *  Should display infobar (counter and arrows at the top)
		 */
		infobar: false,

		/**
		 *  Should image gallery loop
		 */
		loop: true,

		/**
		 * @type {Array<['close' | 'thumbs' | 'fullscreen', 'slideShow']>}
		 * What buttons should appear in the top right corner.
		 * Buttons will be created using templates from `btnTpl` option
		 * and they will be placed into toolbar (class="fancybox-toolbar"` element)
		 */
		buttons: ['close'],

		/**
		 * Base template for layout
		 */
		baseTpl: baseTpl.replace('{{additionalCssClasses}}', additionalCssClasses.join(' ')),

		/**
		 *  Loading indicator template
		 */
		spinnerTpl: '<div class="fancybox-loading"></div>',

		/**
		 *  Error message template
		 */
		errorTpl: '<div class="fancybox-error"><p>{{ERROR}}<p></div>',

		btnTpl: {
			close: closeButton,
			// This small close button will be appended to your html/inline/ajax content by default,
			// if "smallBtn" option is not set to false
			smallBtn: options.modal ? '' : smallButton,
			// Arrows
			arrowLeft: arrowLeftButton,
			arrowRight: arrowRightButton,
		},

		// Internationalization
		lang: 'base',
		i18n: {
			base: oneUiConfig.fancybox.i18n,
		},
		touch: false,
		onDemandConfig: {
			afterShow: null,
		},
		modal: options.modal,
	};
	return config;
}

// Cache holder for the config to reduce dom access
let configCache: ReturnType<typeof getFancyboxConfig>;
