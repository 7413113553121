import T from 'terrific';
import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.CustomerOverlay = T.createModule({

	_selectors: {
		fancyboxB2BCustomer: '#b2bCustomerOverlay',
	},
	_eventNames: {
		CLICK: 'click',
	},

	// eslint-disable-next-line complexity
	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);

		$.fancybox.open({
			src: this._selectors.fancyboxB2BCustomer,
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

});
