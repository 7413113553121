import * as sharedVariables from '@dormakaba/one-ui/assets/css/variables/_variables.scss';
import T from 'terrific';
import $ from 'jquery';
import 'slick-carousel';
import { _slickGlobalConfigs } from '../../../../assets/js/configs/slick/slick';

/**
 * Basic Module description.
 */
T.Module.Checkout = T.createModule({
	_attributes: {},
	_configs: {
		// This key should be used directly as a slick configuration object
		_slick: {
			dots: true,
			infinite: false,
			responsive: [
				{
					breakpoint: sharedVariables.sm,
					settings: {
						arrows: true,
					},
				},
				{
					breakpoint: sharedVariables.md,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						arrows: true,
					},
				},
				{
					breakpoint: sharedVariables.lg,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						arrows: true,
					},
				},
			],
		},
	},
	_selectors: {
		slider: '[data-js-checkout-slick-slider]',
		priceSimulateForCheckout: '[data-js-price-simulate-button-checkout]',
		cartEditCheckBox: '[data-js-edit-checkbox-cart]',
		customerReferenceIdInput: '[data-js-customerReferenceId]',
		checkoutCommission: '[data-js-checkoutCommission]',
		placeOrderButton: '[data-js-place-order-button]',
		placeOrderForm: '.place-order-form1',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$slider = this.$ctx.find(this._selectors.slider);
		this.$ctx.find(this._selectors.placeOrderButton).click(this.onClickPlaceOrder.bind(this));
		this.$ctx.find(this._selectors.priceSimulateForCheckout).click(this.onClickPriceSimulationForCheckout.bind(this));
		this.initSlider();
		this.hideCartEditCheckbox();
		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},


	onClickPlaceOrder(ev) {
		const $loadingSpinner = $('#loadingSpinner');
		$loadingSpinner.css({ display: 'block' });
		const $target = $(ev.target);
		$target.attr('disabled', 'disabled');
		const $form = $(event.target).parents(this._selectors.placeOrderForm);
		$form.submit();
	},

	initSlider() {
		const slickConfig = Object.assign(_slickGlobalConfigs, this._configs._slick);
		this.$slider.slick(slickConfig);
	},

	hideCartEditCheckbox() {
		this.$ctx.find(this._selectors.cartEditCheckBox).each(function () {
			$(this)
				.parent()
				.hide();
		});
	},

	onClickPriceSimulationForCheckout(ev) {
		ev.preventDefault();
		const $target = $(ev.target);
		this.$ctx = $(this._ctx);
		const selectors = this._selectors;
		const $loadingSpinner = $('#loadingSpinner');
		$loadingSpinner.css({ display: 'block' });
		// prevent double clicks
		$target.unbind('click');
		const method = 'GET';
		const $simulateButtonForCheckout = this.$ctx.find(selectors.priceSimulateForCheckout);
		const url = $simulateButtonForCheckout.attr('data-js-price-simulate-for-checkout-url');
		const customerReferenceId = this.$ctx.find(selectors.customerReferenceIdInput).val();
		const checkoutCommission = this.$ctx.find(selectors.checkoutCommission).val();
		$.ajax({
			url,
			data: { isCheckout: true },
			type: method,
			dataType: 'html',
			success: (data) => {
				const responseData = `${data}`;
				$('#js-simulated-price-content-for-checkout').html(responseData);
				$loadingSpinner.css({ display: 'none' });
				$simulateButtonForCheckout.prop('disabled', true);
				this.$ctx.find(selectors.customerReferenceIdInput).val(customerReferenceId);
				this.$ctx.find(selectors.checkoutCommission).val(checkoutCommission);
				this.hideCartEditCheckbox();
			},
			error: (error) => {
				/* eslint-disable no-console */
				console.log(`Failed to find the price Details => ${error}`);
				/* eslint-enable no-console */
				$loadingSpinner.css({ display: 'none' });
			},
		});
	},
});
