import $ from 'jquery';
import { Component, GondelBaseComponent } from '@gondel/core';
import { endPoints } from '@dormakaba/ajax';
import { addNotification, MessageType } from '@dormakaba/notifications';
import TerrificConfig from '../../../../../terrific/assets/js/configs/terrific-config/terrific-config';
import { userService } from '@dormakaba/user-service';

@Component('OrderDownloadPDF')
export default class OrderDownloadPDF extends GondelBaseComponent {
	attributes = {
		data: {
			link: 'js-link',
			blankWindow: 'js-blank-window',
		},
	};
	selectors = {
		linkElementSelector: '[data-js-link]',
	};
	_ctx: HTMLDivElement;
	$elements: any;
	$ctx: any;

	start(): void {
		this.$ctx = $(this._ctx);
		this.$ctx.click(this.handleClickEvent);
	}

	handleClickEvent = async (event: MouseEvent): Promise<void> => {
		event.preventDefault();
		event.stopPropagation();

		const orderId = this.$ctx.data('order-id');
		const errorMessage = this.$ctx.data('error-message');
		const userServiceNode = document.getElementById('user-service-config');

		if (!userServiceNode) return;

		const userServiceConfig = JSON.parse(userServiceNode.innerHTML) as {
			hybrisStoreName: string;
			currentUserId: string;
		};

		if (!userServiceConfig.hybrisStoreName) return;

		const token = await endPoints.oAuthController.getToken.ajax({
			keys: {
				encodedContextPath: TerrificConfig.config.encodedContextPath,
			},
		});

		try {
			await endPoints.ordersController.downloadPDF.ajax({
				keys: {
					language: document.documentElement.lang,
					orderId,
					fileReference: orderId,
					fileName: `${orderId}.pdf`,
					hybrisStore: userServiceConfig.hybrisStoreName,
					token,
					user: userServiceConfig.currentUserId,
				},
			});
		} catch (error) {
			addNotification(MessageType.ERROR, orderId, errorMessage);
		}

		return Promise.resolve(undefined);
	};

	stop(): void {
		this.$ctx.off('click');
	}
}
