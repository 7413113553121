import T from 'terrific';
import $ from 'jquery';
import { toggleContent } from '../../../../assets/js/utils/toggle';

/**
 * Basic Module description.
 */
T.Module.Facets = T.createModule({
	_attributes: {
		moreLessContentToggleTarget: 'data-js-more-less-content-toggle-target',
	},
	_selectors: {
		moreLessContentToggle: '[data-js-more-less-content-toggle-trigger]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$ctx.find(this._selectors.moreLessContentToggle).on(this._eventNames.CLICK, this.moreLessToggle.bind(this));

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	moreLessToggle(ev) {
		const $ctx = this.$ctx;
		const $el = $(ev.currentTarget);
		const target = $el.attr(this._attributes.moreLessContentToggleTarget);

		toggleContent($el, target, $ctx);
	},
});
