import T from 'terrific';
import $ from 'jquery';
import autocomplete from 'autocompleter';
import { endPoints } from '@dormakaba/ajax';
import { htmlEntities } from '@dormakaba/utils';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';

T.Module.Autocomplete = T.createModule({
	_classes: {
		results: 'a-autocomplete__results',
		resultItem: 'a-autocomplete__item',
		resultMatch: 'a-autocomplete__match',
	},
	_data: {
		messages: {
			noResultMessage: 'js-autocomplete-no-results',
		},
	},
	_config: {
		hiddenSuggestion: 'h-d-none',
	},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.setupAutocomplete();

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	setupAutocomplete() {
		const instance = this; // eslint-disable-line
		const ctx = this._ctx;
		const noResultMessage = this.$ctx.data(this._data.messages.noResultMessage);

		autocomplete({
			input: ctx,
			minLength: 3,
			emptyMsg: noResultMessage,
			render(item, value) {
				const itemElement = document.createElement('div');
				itemElement.className = instance._classes.resultItem;

				const regex = new RegExp(value, 'gi');
				const inner = item.term.replace(regex, (match) => {
					return `<span class="${instance._classes.resultMatch}">${htmlEntities.encode(match)}</span>`;
				});
				itemElement.innerHTML = inner;

				// the current input value as default suggestion should not be visible.
				if (item.term === value) {
					itemElement.classList.add(instance._config.hiddenSuggestion);
				}

				return itemElement;
			},
			className: instance._classes.results,
			fetch(text, update) {
				let suggestions;

				text = text.toLowerCase();

				endPoints.ekitTerrificHybrisController.getAutocompleteContent
					.ajax({
						keys: {
							encodedContextPath: TerrificConfig.config.encodedContextPath,
							searchString: text,
						},
					})
					.then((data) => {
						suggestions = [].concat(data.suggestions);

						// in order to autoselect the current input value as default, we need it present
						// as the first suggestion. See DKCOMMERCEKIT-858
						if (suggestions.length > 0) {
							suggestions.unshift({ term: text });
						}

						for (let i = 0; i < data.products.length; i++) {
							const it = data.products[i];
							const description = (it.description && it.description.length > 150) ? `${it.description.substr(0, 150)}...` : it.description; // eslint-disable-line max-len
							it.term = `${it.displayCode} - ${it.name} - ${description}`;
							suggestions.push(it);
						}

						update(suggestions);
					});
			},
			onSelect(item) {
				if (item.url) {
					window.location = TerrificConfig.config.encodedContextPath + item.url;
				} else {
					ctx.value = item.term;
					ctx.parentElement.submit();
				}
			},
		});
	},
});
