import T from 'terrific';
import $ from 'jquery';
import { sendMessage } from '@dormakaba/one-ui/components/utils/ekit-simple-messaging/ekit-simple-messaging.ts';

/**
 * Basic Module description.
 */
T.Module.ProductUnitConversion = T.createModule({
	_configs: {},
	_attributes: {
		data: {
			hiddenQuantityField: 'js-product-unit-conversion-hidden-field',
			conversion: 'js-product-unit-conversion',
			conversionTarget: 'js-product-unit-conversion-target',
			conversionRate: 'js-product-unit-conversion-rate',
			addToItemListQtyTarget: 'js-add-to-itemlist-qty-target',
		},
	},
	_selectors: {
		quantityField: '[data-js-product-unit-conversion-quantity]',
	},
	_eventNames: {
		CHANGE: 'change',
	},
	_stateClasses: {},

	start(resolve) {
		this.$ctx = $(this._ctx);

		const qtyInput = this.$ctx.find(this._selectors.quantityField);
		qtyInput.on(this._eventNames.CHANGE, this.onChangeQuantityValue.bind(this));

		// execute conversion for the first time (only if conversion is needed)
		if ($(qtyInput).data(this._attributes.data.conversion)) {
			qtyInput.each(this.convertBaseUnitToSalesUnit.bind(this));
		}

		resolve();
	},

	stop() {
		// optional -> tidy up your module. Called on application.stop()
		// e.g. this._events.disconnect(); -> disconnect from the event dispatcher
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	// If quantity input field is changed, set hidden Value, enable/disable addToCartButton, execute conversion
	onChangeQuantityValue(ev) {
		const qtyInput = ev.target;
		const $qtyInput = $(qtyInput);
		const target = $qtyInput.data(this._attributes.data.hiddenQuantityField);
		const invalid = Boolean($qtyInput.is(':invalid'));

		if (target) {
			this._events.emit('setHiddenQuantityField', qtyInput, target, invalid);
		}

		if (!invalid && $qtyInput.data(this._attributes.data.conversion)) {
			this.convertBaseUnitToSalesUnit(0, qtyInput);
		}
		this.updateAddToListQty($qtyInput);
	},

	updateAddToListQty($qtyInput) {
		sendMessage('product-qty-updated', $qtyInput.val());
	},

	// Execute conversion
	convertBaseUnitToSalesUnit(i, el) {
		const $el = $(el);
		const unitFieldSelector = $el.data(this._attributes.data.conversionTarget);
		const conversionRate = $el.data(this._attributes.data.conversionRate);
		const value = $el.val() || $el.text();

		$(unitFieldSelector).text(this.getCalculatedValue(value, conversionRate));
	},

	// Calculation
	getCalculatedValue(value, conversionRate) {
		return Math.round(value * conversionRate * 100) / 100;
	},
});

export default T.Module.ProductUnitConversion;
