import $ from 'jquery';
import { Component, GondelBaseComponent } from '@gondel/core';

/**
 * Set datepicker value to today in case express is picked, and remove it otherwise.
 */
@Component('DeliveryMethod')
export default class DeliveryMethod extends GondelBaseComponent {
	$ctx: any;
	$date: any;
	$choices: any;

	_selectors = {
		date: '.m-delivery-method__date.flatpickr-input',
		choice: '.m-delivery-method__choice input[type=radio]',
	};

	start() {
		this.$ctx = $(this._ctx);
		this.$choices = this.$ctx.find(this._selectors.choice);

		this.$choices.each((index, choice) => {
			const $choice = $(choice);
			$choice.change(this.handleChange.bind(this));
		});
	}

	/**
	 * Change datepicker value upon selection.
	 */
	handleChange(event: any) {
		this.$date = this.$ctx.find(this._selectors.date);
		const instance: any = this._ctx.querySelector(this._selectors.date);
		const flatpickr = instance._flatpickr;

		const method = event.target.value;

		if (method === 'EX') {
			flatpickr.setDate(new Date(), true, 'Y-m-d');
			flatpickr._input.setAttribute('disabled', 'disabled');
		} else {
			flatpickr.clear();
			flatpickr._input.removeAttribute('disabled');
		}
	}
}
