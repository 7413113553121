import $ from 'jquery';
import { Component, EventListener, GondelBaseComponent } from '@gondel/core';

@Component('FloatingLabelContainer')
export default class FloatingLabelContainer extends GondelBaseComponent {
	stateClasses: {
		isFilled: string;
	};

	constructor() {
		super();
		this.stateClasses = {
			isFilled: 'a-floating-label-container--is-filled',
		};
	}

	@EventListener('focusin')
	@EventListener('focusout')
	_handleFocusState(event) {
		const $ctx = $(this._ctx);
		const input = $(event.target);

		if (input.val() !== '' || event.type === 'focusin') {
			$ctx.addClass(this.stateClasses.isFilled);
		} else {
			$ctx.removeClass(this.stateClasses.isFilled);
		}
	}
}
