import T from 'terrific';
import $ from 'jquery';
import * as Cookies from 'browser-cookies';

/**
 * Basic Module description.
 */
T.Module.CookieDisclaimer = T.createModule({
	_selectors: {
		cookieAcceptance: '[data-js-cookie-acceptance]',
		cookiePolicies: '[data-js-cookie-policies]',
		cookieUserSection: '[data-js-cookie-section]',

	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {
		hideClass: 'h-d-none',
	},
	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);
		this.$cookiesOkay = this.$ctx.find(this._selectors.cookieAcceptance);
		this.$cookiesMore = this.$ctx.find(this._selectors.cookiePolicies);
		this.$cookiesUser = this.$ctx.find(this._selectors.cookieUserSection);

		const loggedInUser = this.$cookiesUser.attr('data-js-user-uid');

		if (Cookies.get('userId') === null) {
			this.$cookiesUser.removeClass('h-d-none');
		}

		if (Cookies.get('userId') !== loggedInUser) {
			this.$cookiesUser.removeClass('h-d-none');
		}

		this.$cookiesOkay.on(this._eventNames.CLICK, () => {
			Cookies.set(
				'userId', loggedInUser,
				{ expires: 365 },
				{ secure: true, domain: '' }
			);
			this.$cookiesUser.addClass('h-d-none');
		});

		this.$cookiesMore.on(this._eventNames.CLICK, () => {

		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},
});

