import { OneUiFancyBoxSettings } from '../fancybox/fancybox';

/**
 * The following values will always be available from the backend
 */
interface OnUiConfigInterface {
	fancybox: OneUiFancyBoxSettings;
}

// The config is immutable
type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };

// Cache holder for the config to reduce dom access
let configCache: OnUiConfigInterface;

/**
 * Expect that every page contains a configuration from the backend in json like
 * <script type="text/json">{ ... }</script>
 */
function getConfigFromDom() {
	try {
		return JSON.parse(document.querySelector('#oneui-config').innerHTML) as OnUiConfigInterface;
	} catch (e) {
		throw new Error('Could not read one-ui-config from #oneui-config ' + e);
	}
}

export default {
	// Use a getter for lazy dom access
	get config(): DeepReadonly<OnUiConfigInterface> {
		if (!configCache) {
			configCache = getConfigFromDom();
		}
		return configCache;
	},
};
