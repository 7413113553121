/**
 * Global function to set a value to an input element in React and trigger the "change" event
 *
 * @param {Object} $targetInput jQuery object used for the input element in React.
 * @param {string} value value to set on the target input element
 * @returns {undefined}
 */
export function updateInputForReact($targetInput, value) {
	// The input element's value and the "change" event have to be used without jQuery because
	// the "trigger" method doesn't dispatch a native event!
	if (value && $targetInput.length > 0) {
		const nativeTargetInputElement = $targetInput.get(0);
		const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
		nativeInputValueSetter.call(nativeTargetInputElement, value);
		const ev2 = new Event('change', { bubbles: true });
		nativeTargetInputElement.dispatchEvent(ev2);
	}
}
