// @ts-check
import { endPoints } from '@dormakaba/ajax';
import $ from 'jquery';
import { toggleContent } from '../../../../assets/js/utils/toggle'; // eslint-disable-line import/no-unresolved, max-len
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';
/** @type {any} T - Global Terrific Namespace*/
const T = require('terrific');

/**
 * Basic Module description.
 */
T.Module.ProjectList = T.createModule({
	_attributes: {
		moreLessContentToggleTarget: 'data-js-more-less-content-toggle-target',
		data: {
			mergeOverlayBool: 'js-project-list-merge-overlay-bool',
			projectDetailsLink: 'js-project-list-details-link',
			projectName: 'js-project-list-project-name',
		},
	},
	_selectors: {
		projectListLinkElement: '[data-js-project-list-details-link]',
		moreLessContentToggle: '[data-js-more-less-content-toggle-trigger]',
	},
	_eventNames: {
		CLICK: 'click',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.showMergeOverlay = this.$ctx.data(this._attributes.data.mergeOverlayBool);

		this.$ctx.find(this._selectors.moreLessContentToggle).on(this._eventNames.CLICK, this.moreLessToggle.bind(this));

		this.$ctx.on(
			this._eventNames.CLICK, this._selectors.projectListLinkElement, this.handleClickOfProjectListRow.bind(this)
		);

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	moreLessToggle(ev) {
		const $ctx = this.$ctx;
		const $el = $(ev.currentTarget);
		const target = $el.attr(this._attributes.moreLessContentToggleTarget);

		toggleContent($el, target, $ctx);
	},

	handleClickOfProjectListRow(ev) {
		const $row = $(ev.currentTarget);
		const link = $row.data(this._attributes.data.projectDetailsLink);

		if (this.showMergeOverlay) {
			const projectName = $row.data(this._attributes.data.projectName);
			const dataObject = {
				projectName,
				projectDetailUrl: link,
			};

			endPoints.ekitTerrificHybrisController.postMergeCartOverlayForProjectContext.ajax({
				keys: {
					encodedContextPath: TerrificConfig.config.encodedContextPath,
					CSRFToken: TerrificConfig.config.CSRFToken,
				},
				body: dataObject,
			}).then((data) => {
				this._events.emit(T.EventName.Utils.LAYER_ONDEMAND, data.layer);
			});
		} else {
			window.open(link, '_self');
		}
	},

});
