import T from 'terrific';
import $ from 'jquery';

/**
 * Basic Module description.
 */
T.Module.BackToTop = T.createModule({
	_configs: {
		scrollHeight: document.documentElement.clientHeight * 0.5,
		fadeOutDelay: 2000,
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {
		isHidden: 'a-back-to-top--is-hidden',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);

		this.$ctx.on(this._eventNames.CLICK, () => {
			this.scrollToTop();
		});

		// Catch and destruct CustomEvent (throttled window.onscroll)
		this._events.on('windowScroll', () => {
			this.toggleButton();
		});

		resolve();
	},

	scrollToTop() {
		this._events.emit('scrollToTop');
	},

	fadeOutButton() {
		this.$ctx.addClass(this._stateClasses.isHidden);
	},

	/**
	 * Toggle button appearance depending on vertical scroll height
	 * @returns {undefined}
	 */
	toggleButton() {
		const pixelsScrolled = $(window).scrollTop();
		if (pixelsScrolled > this._configs.scrollHeight) {
			this.$ctx.removeClass(this._stateClasses.isHidden);

			if (this.timedOut) {
				clearTimeout(this.timedOut);
			}
			// set timeout for fade out of button.
			this.timedOut = setTimeout(this.fadeOutButton.bind(this), this._configs.fadeOutDelay);
		} else {
			this.$ctx.addClass(this._stateClasses.isHidden);
		}
	},
});
