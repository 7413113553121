import T from 'terrific';
import $ from 'jquery';
import { toggleContent } from '../../../../assets/js/utils/toggle';

/**
 * Basic Module description.
 */
T.Module.OfferProductListItems = T.createModule({
	_attributes: {
		data: {
			moreLessContentToggleTarget: 'js-more-less-content-toggle-target',
		},
	},
	_selectors: {
		moreLessContentToggle: '[data-js-more-less-content-toggle-trigger]',
	},
	_eventNames: {
		CLICK: 'click',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		this.$moreLessContentToggle = this.$ctx.find(this._selectors.moreLessContentToggle);
		this.$moreLessContentToggle.on(this._eventNames.CLICK, this.moreLessToggle.bind(this));

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	moreLessToggle(ev) {
		const $ctx = this.$ctx;
		const $el = $(ev.currentTarget);
		const target = $el.data(this._attributes.data.moreLessContentToggleTarget);

		toggleContent($el, target, $ctx);
	},
});
