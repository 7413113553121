import T from 'terrific';
import $ from 'jquery';
import * as toggle from '../../../../assets/js/utils/toggle';

T.Module.MyAccountFlyout = T.createModule({
	_attributes: {
		targetDevice: 'data-js-trigger-target-device',
	},
	_selectors: {
		toggleTrigger: '[data-js-my-account-flyout-toggle-trigger]',
		logoutTrigger: '[data-js-my-account-flyout-logout-trigger]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_data: {
		// Target selectors of elements to be toggled, so component knows its
		// selectors itself and is not reliant on having them passed as a value.
		targetSelectors: {
			mobile: '[data-js-my-account-flyout-mobile]',
			desktop: '[data-js-my-account-flyout-desktop]',
		},
	},

	start(resolve) {
		this.$ctx = $(this._ctx);

		this.$ctx.find(this._selectors.toggleTrigger)
			.on(this._eventNames.CLICK, (ev) => {
				this.toggleMyAccountFlyout(ev);
			});

		this.$ctx.find(this._selectors.logoutTrigger)
			.on(this._eventNames.CLICK, (ev) => {
				this.toggleMyAccountlogoutFlyout(ev);
			});

		this._events.on('closeActiveToggle', () => {
			// Condition to prevent multiple execution
			if (toggle.isActive(this.$ctx, this._selectors.toggleTrigger)) {
				this.$ctx
					.find(this._selectors.toggleTrigger)
					.trigger(this._eventNames.CLICK);
			}
		});

		// Event gets received by all instances (triggers, targets, mobile|desktop)
		this._events.on('toggableMenuClicked', (targetDevice, sendingTriggerSelector) => {
			toggle.onToggableMenuClicked(
				this.$ctx,
				sendingTriggerSelector,
				this._selectors.toggleTrigger,
				this._data.targetSelectors[targetDevice]
			);
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	toggleMyAccountlogoutFlyout() {
		sessionStorage.removeItem('SessionAddresses');
	},

	toggleMyAccountFlyout(ev) {
		const $el = $(ev.currentTarget);
		const targetDevice = $el.attr(this._attributes.targetDevice);
		const targetSelector = this._data.targetSelectors[targetDevice];

		this._events.emit('toggableMenuClicked', targetDevice, this._selectors.toggleTrigger);

		toggle.toggleContent($el, targetSelector);

		if (toggle.isActive(this.$ctx, this._selectors.toggleTrigger)) {
			this._events.emit('bindClickToBodyToCloseActiveToggle');
		} else {
			this._events.emit('unBindClickToBodyToCloseActiveToggle');
		}
	},
});
