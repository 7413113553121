import currencyFormatter from 'currency-formatter';
import TerrificConfig from '../configs/terrific-config/terrific-config.ts';

const currencyFormatOptions = {
	code: TerrificConfig.config.locale.currency,
	locale: TerrificConfig.config.locale.language,
	symbol: TerrificConfig.config.locale.currency,
	spaceBetweenAmountAndSymbol: TerrificConfig.config.locale.spaceBetweenAmountAndSymbol,
};

/**
	* Returns config of used currency.
	* @returns {Object} currencyFormat
*/
export function getCurrencyFormat() {
	const currencyFormatterFormat = currencyFormatter.findCurrency(currencyFormatOptions.code);
	const currencyFormat = $.extend(currencyFormatterFormat, currencyFormatOptions);
	return currencyFormat;
}

/**
	* Deformats currency to number value.
	* @param {string} value: value to be deformatted
	* @returns {number} deformatted value
*/
export function deformatValue(value) {
	const config = getCurrencyFormat();
	config.symbol = '';
	return currencyFormatter.unformat(value, config);
}

/**
	* Formats number to currency string.
	* @param {number | string} value: value to be formatted
	* @param {boolean} hideSymbol: bool for hiding symbol
	* @returns {string} formatted value
*/
export function formatValue(value, hideSymbol = false) {
	const config = getCurrencyFormat();
	if (hideSymbol) {
		config.symbol = '';
		config.spaceBetweenAmountAndSymbol = false;
	}
	return currencyFormatter.format(value, config);
}
