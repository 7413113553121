/**
 * The following values will always be available from the backend
 */
interface TerrificConfigInterface {
	contextPath: string;
	encodedContextPath: string;
	store: string;
	dateFormat: string;
	locale: {
		language: string;
		currency: string;
	};
}

// The config is immutable
type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };

// Cache holder for the config to reduce dom access
let configCache: TerrificConfigInterface;

/**
 * Expect that every page contains a configuration from the backend in json like
 * <script type="text/json">{ ... }</script>
 */
function getConfigFromDom() {
	try {
		return JSON.parse(document.querySelector('#terrific-config').innerHTML) as TerrificConfigInterface;
	} catch (e) {
		throw new Error('Could not read terrific-config from #terrific-config ' + e);
	}
}

export function reloadConfig() {
	configCache = getConfigFromDom();
}
export default {
	// Use a getter for lazy dom access
	get config(): DeepReadonly<TerrificConfigInterface> {
		if (!configCache) {
			configCache = getConfigFromDom();
		}
		return configCache;
	},
};
