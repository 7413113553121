import * as sharedVariables from '@dormakaba/one-ui/assets/css/variables/_variables.scss';
import { getFancyboxConfig } from '@dormakaba/one-ui/components/configs/fancybox/fancybox.ts';
import T from 'terrific';
import $ from 'jquery';
import 'slick-carousel';
import { _slickGlobalConfigs } from '../../../../assets/js/configs/slick/slick';

/**
 * Basic Module description.
 */
T.Module.ProductImageGallery = T.createModule({
	_configs: {
		_slickMain: {
			fade: true,
			arrows: false,
			vertical: false,
			/* eslint-disable max-len */
			prevArrow: '<button class="a-button a-button--single-icon m-product-image-gallery__button--left"><i class="a-icon a-icon__before--arrow-thin-left "></i></button>',
			nextArrow: '<button class="a-button a-button--single-icon m-product-image-gallery__button--right"><i class="a-icon a-icon__before--arrow-thin-right "></i></button>',
			/* eslint-enable max-len */
			responsive: [
				{
					breakpoint: sharedVariables.xs,
					settings: {
						dots: true,
					},
				},
				{
					breakpoint: sharedVariables.sm,
					settings: {
						dots: false,
					},
				},
			],
		},
		_slickThumbnails: {
			fade: false,
			vertical: true,
			slidesToScroll: 1,
			focusOnSelect: true,
			arrows: true,
			/* eslint-disable max-len */
			prevArrow: '<button class="a-button a-button--single-icon"><i class="a-icon a-icon__before--arrow-up "></i></button>',
			/* eslint-enable max-len */
			responsive: [
				{
					breakpoint: sharedVariables.xs,
					settings: 'unslick',
				},
				{
					breakpoint: sharedVariables.sm,
					settings: {
						slidesToShow: 4,
					},
				},
			],
		},
	},
	_selectors: {
		mainSlider: '[data-js-main-slider-container]',
		thumbnailsSlider: '[data-js-thumbnails-slider-container]',
		arrowDown: '[data-js-thumbnails-slider-arrow-down]',
		fancyboxImages: '[data-js-fancybox-gallery]',
		activeImage: '.slick-current.slick-active',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_stateClasses: {
		displayNone: 'h-d-none',
		mainSliderThemeClass: 'slick-slider--main-bordered',
		thumbnailsSliderThemeClass: 'slick-slider--thumbnails-bordered',
		currentIndex: 'slick-index',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.thumbnailsSliderIsInitialized = false;
		this.$mainSlider = this.$ctx.find(this._selectors.mainSlider);
		this.$thumbnailsSlider = this.$ctx.find(this._selectors.thumbnailsSlider);
		this.$images = this.$ctx.find(this._selectors.fancyboxImages);

		// init main slider component if a slider is present
		if (this.$mainSlider.length !== 0) {
			this.initMainSlider();
			this.initThumbnailsSlider();
		} else {
			this.$images.on(this._eventNames.CLICK, this.onActiveImageClick.bind(this));
		}

		/* eslint-disable complexity */
		this._events.on('breakpointChange', (breakpoint) => {
			if (Object.values(breakpoint).includes('xs') && this.thumbnailsSliderIsInitialized) { // eslint-disable-line max-len, eqeqeq
				this.removeThumbnailSlider();
			}

			if (Object.values(breakpoint).includes('sm') || Object.values(breakpoint).includes('md') || Object.values(breakpoint).includes('lg') || Object.values(breakpoint).includes('xl')) { // eslint-disable-line eqeqeq, max-len
				this.initThumbnailsSlider();
			}
		});
		/* eslint-enable complexity */

		resolve();
	},

	sync() {

	},

	initMainSlider() {
		const slickConfig = Object.assign(_slickGlobalConfigs, this._configs._slick, this._configs._slickMain, {
			asNavFor: this._selectors.thumbnailsSlider,
		});

		const additionalFancyboxOptions = {
			infobar: true,
			touch: true,
			beforeClose: (instance) => {
				this.$mainSlider.slick('slickGoTo', instance.currIndex);
			},
		};

		this.fancyboxConfig = $.extend({}, $.fancybox.defaults,
			getFancyboxConfig({ width: 'full' }),
			additionalFancyboxOptions
		);

		this.$mainSlider
			.addClass(this._stateClasses.mainSliderThemeClass)
			// prevents multiple initializations
			.not('.slick-initialized')
			.slick(slickConfig);

		this.$mainSlider.on('click', this.onActiveImageClick.bind(this));
	},

	onActiveImageClick(ev) {
		const currentImageIndex = $(ev.currentTarget)
			.find(this._selectors.activeImage)
			.data(this._stateClasses.currentIndex);

		$.fancybox.open(this.$images, this.fancyboxConfig, currentImageIndex);
		return false;
	},

	initThumbnailsSlider() {
		if (!this.thumbnailsSliderIsInitialized) {
			const slickConfig = Object.assign(_slickGlobalConfigs, this._configs._slick, this._configs._slickThumbnails, {
				asNavFor: this._selectors.mainSlider,
				nextArrow: $(this._selectors.arrowDown),
			});

			this.$thumbnailsSlider
				.removeClass(this._stateClasses.displayNone)
				.addClass(this._stateClasses.thumbnailsSliderThemeClass)
				// prevents multiple initializations
				.not('.slick-initialized')
				.slick(slickConfig);

			this.$thumbnailsSlider.on('init', () => {
				this.adjustSlickThumbnailSliderHeight();
				this.thumbnailsSliderIsInitialized = true;
			});

			this.$thumbnailsSlider.on('setPosition', () => {
				this.adjustSlickThumbnailSliderHeight();
			});
		}
	},

	removeThumbnailSlider() {
		this.$thumbnailsSlider.addClass(this._stateClasses.displayNone);
		this.thumbnailsSliderIsInitialized = false;
	},

	// readjust height on init and on resize
	adjustSlickThumbnailSliderHeight() {
		const sliderHeight = this.$ctx.find(this._selectors.mainSlider).height();

		this.$ctx.find(this._selectors.thumbnailsSlider).css('height', sliderHeight - 60);
		this.$ctx.find(this._selectors.thumbnailsSlider).find('.slick-list').css('height', sliderHeight - 100);
	},
});
