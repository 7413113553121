import T from 'terrific';
import $ from 'jquery';
import doT from 'dot';
import TerrificConfig from '../../../../assets/js/configs/terrific-config/terrific-config.ts';
import { formatValue, deformatValue } from '../../../../assets/js/utils/currencyFormatter';

/**
 * Basic Module description.
 */
T.Module.OfferProductList = T.createModule({
	_attributes: {
		data: {
			listItem: 'js-offer-product-list-item',
			showButton: 'js-offer-product-list-show',
			hideButton: 'js-offer-product-list-hide',
		},
	},
	_selectors: {
		itemPriceElement: '[data-js-offer-product-list-item-price-element]',
		totalPrice: '[data-js-offer-product-list-total-price]',
		dynamicProductList: '[data-js-dynamic-offer-product-list]',
		customProductList: '[data-js-custom-products-offer-product-list]',
		customProductItemTemplate: '[data-js-offer-product-list-custom-product-item]',
		customProductForm: '[data-js-offer-product-list-custom-product-form]',
		formNameInput: '#project-list-own-products-name',
		formDescriptionInput: '#project-list-own-products-description',
		formPricePerPieceInput: '#project-list-own-products-price-per-piece',
		formQuantity: '#quantity_offer-product-list-static',
		formProductListId: '#project-list-own-products-list-id',
		customProductEditButton: '[data-js-product-edit-button]',
		fancyboxFormContainer: '#project-list-own-products',
		visibilityToggleButton: '[data-js-offer-product-list-visibility-toggle]',
		productUnitConversionQuantity: '[data-js-product-unit-conversion-quantity]',
	},
	_eventNames: {
		CLICK: 'click',
		SUBMIT: 'submit',
		INPUT_VALUES_CHANGED: 'currencyFormatterInputChanged',
		REPLACE_DYNAMIC_PRODUCT_LIST: 'replaceDynamicProductList',
	},
	_stateClasses: {
		printInvisibleClass: 'h-d-print-none',
	},

	start(resolve) {
		// this.$ctx will contain a jQuery reference to the root element in the DOM.
		this.$ctx = $(this._ctx);
		this.$listContainer = this.$ctx.find(this._selectors.customProductList);
		this.$customProductsForm = this.$ctx.find(this._selectors.customProductForm);
		this.$moreLessContentToggle = this.$ctx.find(this._selectors.moreLessContentToggle);
		this.$pricePerPiece = this.$customProductsForm.find(this._selectors.formPricePerPieceInput);

		this.$ctx.on(
			this._eventNames.CLICK, this._selectors.visibilityToggleButton, this.toggleVisibility.bind(this)
		);

		this.$customProductsForm.on(this._eventNames.SUBMIT, this.handleOnsave.bind(this));

		this._events.on(this._eventNames.INPUT_VALUES_CHANGED, this.setTotalPrice.bind(this));
		this._events.on(this._eventNames.REPLACE_DYNAMIC_PRODUCT_LIST, this.replaceDynamicProductList.bind(this));

		this.setTotalPrice();

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	handleOnsave(ev) {
		ev.preventDefault();
		const data = this.getFormData(this.$customProductsForm);

		if (data.index) {
			const newItem = this.createRowItem(data);
			this.replaceCustomProduct(data, newItem);
		} else {
			// listContainer has an additional child. This way, we start indexing with zero.
			data.index = this.$listContainer.children().length - 1;
			const newItem = this.createRowItem(data);
			this.addCustomProduct(data, newItem);
		}

		// Update total price, close fancybox and clean up form.
		$.fancybox.close();
		this.setTotalPrice();

		// reset whole form
		this.$customProductsForm.trigger('reset');
		// reformat input field after reset
		this.$pricePerPiece.val(formatValue(0, true));
	},

	replaceCustomProduct(data, newItem) {
		// unregister callback from row, that is to be removed from the DOM.
		let $editButton = this.getRowEditButton(data.index);
		$editButton.off();

		this.$listContainer.find(`#custom-offer-product-list-item-${data.index}`).replaceWith(newItem);

		// register callback of new edit button.
		$editButton = this.getRowEditButton(data.index);
		$editButton.on(this._eventNames.CLICK, this.openCustomProductsForm.bind(this, data));
	},

	addCustomProduct(data, newItem) {
		this.$listContainer.append(newItem);

		// register callback function for new edit button, update total price, and close fancybox.
		const $newEditButton = this.$listContainer.find(this._selectors.customProductEditButton).last();
		$newEditButton.on(this._eventNames.CLICK, this.openCustomProductsForm.bind(this, data));
	},

	setTotalPrice() {
		const $totalPrice = this.$ctx.find(this._selectors.totalPrice);
		const newTotalPrice = this.sumInputValues();
		$totalPrice.text(newTotalPrice);
	},

	sumInputValues() {
		let value = 0;
		// Search for all dynamic & custom product-lists
		const $lists = this.$ctx.find(`${this._selectors.dynamicProductList}, ${this._selectors.customProductList}`);

		$lists.each((id, list) => {
			// Search for all product items
			const $items = $(list).find('[id^="offer-product-list-item-"], [id^="custom-offer-product-list-item-"]');
			$items.each((id2, item) => {
				const $item = $(item);
				if (!$item.hasClass(this._stateClasses.printInvisibleClass)) {
					const $priceElement = $item.find(this._selectors.itemPriceElement);
					const quantity = $item.find(this._selectors.productUnitConversionQuantity).text();
					value += this.getInputValue($priceElement) * parseInt(quantity, 10);
				}
			});
		});

		return formatValue(value);
	},

	getInputValue(el) {
		const $el = $(el);
		const value = $el.val() || $el.text();
		return deformatValue(value);
	},

	getRowEditButton(rowIndex) {
		return this.$listContainer.find(
			`#custom-offer-product-list-item-${rowIndex}
			${this._selectors.customProductEditButton}`
		);
	},

	openCustomProductsForm(data) {
		data.price = formatValue(data.price, true);
		this.fillEditForm(data);

		// contrary to product create, on edit we do not want form changes to persist after close.
		$.fancybox.open({
			src: this._selectors.fancyboxFormContainer,
			opts: {
				afterClose: () => {
					this.$customProductsForm.trigger('reset');
					this.$customProductsForm.find(this._selectors.formProductListId).val('');
				},
			},
		});
	},

	getFormData(form) {
		const data = { 'currency': TerrificConfig.config.locale.currency };
		const formDataArray = form.serializeArray();

		formDataArray.forEach((el) => {
			data[el.name] = el.value;
		});

		data.price = formatValue(data.price, true);

		return data;
	},

	fillEditForm(data) {
		const $nameInput = this.$customProductsForm.find(this._selectors.formNameInput);
		const $descriptionInput = this.$customProductsForm.find(this._selectors.formDescriptionInput);
		const $qty = this.$customProductsForm.find(this._selectors.formQuantity);
		const $listId = this.$customProductsForm.find(this._selectors.formProductListId);

		$nameInput.val(data.name);
		$descriptionInput.val(data.description);
		this.$pricePerPiece.val(formatValue(data.price, true));
		$qty.val(data.quantity);
		$listId.val(data.index);
	},

	createRowItem(data) {
		const template = this.$ctx.find(this._selectors.customProductItemTemplate).html();
		const rowItem = doT.template(template)(data);

		return rowItem;
	},

	replaceDynamicProductList(layer) {
		const productListContainer = this.$ctx.find(this._selectors.dynamicProductList);
		productListContainer.empty();
		productListContainer.append(layer);
		this._sandbox.addModules(this._ctx);
		this.setTotalPrice();
	},

	toggleVisibility(ev) {
		const $button = $(ev.target);
		const showButton = $button.data(this._attributes.data.showButton);
		const hideButton = $button.data(this._attributes.data.hideButton);
		const listItemId = $button.data(this._attributes.data.listItem);
		const $listItem = this.$ctx.find(`${listItemId}`);

		if ($listItem.hasClass(this._stateClasses.printInvisibleClass)) {
			$button.html(showButton);
		} else {
			$button.html(hideButton);
		}
		$listItem.toggleClass(this._stateClasses.printInvisibleClass);
		this.setTotalPrice();
	},
});
