import $ from 'jquery';
import { Component, GondelBaseComponent } from '@gondel/core';
import { element } from './selectors';
import { activeClass, noFocusClass } from './attributes';
import { anchor } from './attributes';
import { toggleTrigger } from './selectors';
import { collapse } from './attributes';

@Component('Collapse')
export default class Collapse extends GondelBaseComponent {
	$ctx: any;
	$els: any;
	isCollapse?: boolean;

	_config = {
		headerHeight: 80,
	};

	_attributes = {
		data: {
			anchor: anchor,
		},
		isCollapse: collapse,
	};

	_eventNames = {
		CLICK: 'click',
		TRANSITION_END: 'transitionend webkitTransitionEnd oTransitionEnd',
	};

	_stateClasses = {
		activeClass: activeClass,
		noFocusClass: noFocusClass,
	};

	_selectors = {
		collapseElements: `[${element}]`,
		toggleTrigger: `[${toggleTrigger}]`,
	};

	start() {
		this.$ctx = $(this._ctx);
		this.$els = this.$ctx.find(this._selectors.collapseElements);
		this.isCollapse = this._ctx.hasAttribute(this._attributes.isCollapse);

		// iterate over every collapsoble container...
		this.$els.each((index, el) => {
			const $el = $(el);

			if (this.isCollapse) {
				this.registerCollapseTrigger(index, $el);
			} else {
				this.registerToggleTrigger($el);
			}

			this.showIfReferenced($el);

			// initial focus of viewport for active element
			this.focusOnContent($el);
		});
	}

	registerToggleTrigger($el) {
		const $toggleTrigger = $el.find(this._selectors.toggleTrigger);
		if ($toggleTrigger) {
			$toggleTrigger.each((index, trigger) => {
				const $trigger = $(trigger);
				$trigger.on(this._eventNames.CLICK, this.toggleContent.bind(this, $el));
				$trigger.css('cursor', 'pointer');
			});
		}
	}

	showIfReferenced($el) {
		const isComponentReferenced =
			location.hash.length !== 0 && location.hash.substring(1) === $el.data(this._attributes.data.anchor);

		if (isComponentReferenced) {
			this.showContent($el, true);
		}
	}

	showContent($el, shouldElementGetFocus = false) {
		$el.addClass(this._stateClasses.activeClass);
		if (shouldElementGetFocus) {
			// make sure to scroll only after css transition ends.
			$el.on(this._eventNames.TRANSITION_END, ev => {
				$(window).scrollTop($(ev.target).offset().top - this._config.headerHeight);
				$(this).off(ev);
			});
		}
	}

	focusOnContent($el) {
		if (
			$el.hasClass(this._stateClasses.activeClass) &&
			!$el.hasClass(this._stateClasses.noFocusClass) &&
			location.hash.length === 0
		) {
			$(window).scrollTop($el.offset().top - this._config.headerHeight);
		}
	}

	collapseContent($el) {
		$el.removeClass(this._stateClasses.activeClass);
	}

	toggleContent($el) {
		$el.toggleClass(this._stateClasses.activeClass);
	}

	registerCollapseTrigger(elementIndex, $el) {
		const $toggleTrigger = $el.find(this._selectors.toggleTrigger);
		$toggleTrigger.each((index, trigger) => {
			$(trigger).on(this._eventNames.CLICK, () => this.collapseRemainingElements(elementIndex));
		});
	}

	collapseRemainingElements(exclusion) {
		let $el;
		this.$els.each((index, el) => {
			$el = $(el);

			if (index !== exclusion) {
				this.collapseContent($el);
			} else {
				this.toggleContent($el);
			}
		});
	}
}
