import T from 'terrific';
import $ from 'jquery';
import { addNotification, MessageType } from '@dormakaba/notifications';
import { AbstractCart } from '../../../../assets/js/utils/abstract-cart';

/**
 * Basic Module description.
 */
T.Module.Cart = T.createModule(
	$.extend(
		AbstractCart,
		{
			_selectors: {
				editButton: '[data-js-edit-btn-cart]',
				cancelButton: '[data-js-cancel-edit-btn-cart]',
				checkBox: '[data-js-edit-checkbox-cart]',
				deleteEntriesButton: '[data-js-delete-entries-cart]',
				priceSimulateButton: '[data-js-price-simulate-button-cart]',
				completeOrderButton: '[data-js-checkout-button]',
				fileUploadButton: '[data-js-file-button]',
				deleteFileUploadButton: '[data-js-delete-file-upload-button]',
			},

			start(resolve) {
				// bind ctx
				this.$ctx = $(document);
				const selectors = this._selectors;
				this.hideElementsOnRefresh();

				this.bindCommonEvents();
				this.$ctx.on('click', selectors.completeOrderButton, this.onClickCompleteOrder.bind(this));
				this.$ctx.on('click', selectors.editButton, this.onClickEditButtonForCart.bind(this));
				this.$ctx.on('click', selectors.cancelButton, this.onClickCancelButtonForCart.bind(this));
				this.$ctx.on('click', selectors.fileUploadButton, this.onClickFileUploadForCartEntry.bind(this));
				this.$ctx.on('click', selectors.deleteFileUploadButton, this.onClickDeleteFileForCartEntry.bind(this));


				resolve();
			},

			sync() {
				// Called when start() method of all registered modules was called.
			},

			onClickCompleteOrder() {
				const $loadingSpinner = $('#loadingSpinner');
				$loadingSpinner.css({ display: 'block' });
			},

			onClickDeleteFileForCartEntry(ev) {
				ev.preventDefault();
				this.$ctx = $(this._ctx);
				let $target = $(ev.target);
				const selectors = this._selectors;

				// prevent double clicks
				$target = $target.is(selectors.deleteFileUploadButton) ? $target :
					$target.parents(selectors.deleteFileUploadButton);
				const entry = $target.data('js-entry-value');
				$target.unbind('click');
				const $deleteFileUploadButton = this.$ctx.find(selectors.deleteFileUploadButton);
				const url = `${$deleteFileUploadButton.attr('data-js-check-delete-file-upload-url')}?entryNumber=${entry}`;
				$.ajax({
					url,
					type: 'GET',
					success: (response) => {
						if (response === 'OK') {
							const messageText = $(`${'.js-delete-button-'}${entry}`).data('js-delete-success-message');
							addNotification(MessageType.SUCCESS, messageText, messageText);
							$(`${'.js-item-uploaded-file-'}${entry}`).attr('hidden', true);
							$(`${'.js-delete-button-'}${entry}`).hide();
						} else {
							const messageText = $(`${'.js-delete-button-'}${entry}`).data('js-upload-error-message');
							addNotification(MessageType.ERROR, messageText, messageText);
						}
					},
					error: (xhr, textStatus, errorThrown) => {
						/* eslint-disable no-console */
						console.log('Adding failed, details below \n-----------START-----------');
						console.log(`xhr => ${xhr}`);
						console.log(`textStatus => ${textStatus}`);
						console.log(`errorThrown => ${errorThrown}`);
						console.log('----------END----------');
						/* eslint-enable no-console */
					},
				});
			},

			onClickFileUploadForCartEntry(ev) {
				ev.preventDefault();
				const $target = $(ev.target);
				this.$ctx = $(this._ctx);
				const selectors = this._selectors;
				const $fileUploadButton = this.$ctx.find(selectors.fileUploadButton);
				const url = $fileUploadButton.attr('data-js-check-file-upload-url');
				$target.siblings('form').find('input[type="file"]').trigger('click');
				/* eslint-disable prefer-arrow-callback */
				$('input[type="file"]').on('change', function (e) {
					const file = e.target.files[0];
					const entryNumber = $(e.target).siblings().find('input[name="entryNumber"]');
					const formData = new FormData();
					formData.append('uploadedFile', file);
					formData.append('entryNumber', entryNumber.val());
					formData.append('CSRFToken', $('#js-CSRFToken').val());
					const entry = entryNumber.val();
					$.ajax({
						url,
						type: 'POST',
						data: formData,
						contentType: false,
						processData: false,
						async: true,
						success: (response) => {
							if (response.statusCode === 'OK') {
								const messageText = $(`${'.js-item-uploaded-file-'}${entry}`).data('js-upload-success-message');
								addNotification(MessageType.SUCCESS, messageText, messageText);
								$(`${'.js-item-uploaded-file-'}${entry}`).prop('href', response.url);
								$(`${'.js-item-uploaded-file-'}${entry}`).text(response.realFileName);
								$(`${'.js-item-uploaded-file-'}${entry}`).removeAttr('hidden');
								$(`${'.js-delete-button-'}${entry}`).show();
							} else if (response.statusCode === 'UNSUPPORTED_MEDIA_TYPE') {
								const messageText = $(`${'.js-item-uploaded-file-'}${entry}`).data('js-upload-not-supported-message');
								addNotification(MessageType.WARNING, messageText, messageText);
							} else if (response.statusCode === 'NOT_ACCEPTABLE') {
								const messageText = $(`${'.js-item-uploaded-file-'}${entry}`).data('js-upload-max-size-message');
								addNotification(MessageType.ERROR, messageText, messageText);
							} else {
								const messageText = $(`${'.js-item-uploaded-file-'}${entry}`).data('js-upload-error-message');
								addNotification(MessageType.ERROR, messageText, messageText);
							}
							e.target.value = '';
						},
						error: (xhr, textStatus, errorThrown) => {
							/* eslint-disable no-console */
							console.log('Adding failed, details below \n-----------START-----------');
							console.log(`xhr => ${xhr}`);
							console.log(`textStatus => ${textStatus}`);
							console.log(`errorThrown => ${errorThrown}`);
							console.log('----------END----------');
							/* eslint-enable no-console */
						},
					});
					e.stopImmediatePropagation();
				});
			},

			onClickEditButtonForCart() {
				this.onClickEditButton();

				const completeOrderBtn = this.$ctx.find(this._selectors.completeOrderButton);
				$(completeOrderBtn).hide();
			},

			onClickCancelButtonForCart() {
				this.onClickCancelButton();

				const completeOrderBtn = this.$ctx.find(this._selectors.completeOrderButton);
				$(completeOrderBtn).show();
			},

			controlElementsOnInvalidItems() {
				const ctx = this.$ctx;
				if (ctx.find(this._commonSelectors.containsNonPurchasableCheckbox).prop('checked')) {
					this.disableCompleteButton();
				}
			},

			displayItemAddNotification(deleteFromSource) {
				let message = '';
				if (this.isTrue(deleteFromSource)) {
					message = 'cart.notification.wishlist.move.success';
				} else {
					message = 'cart.notification.wishlist.copy.success';
				}
				addNotification(MessageType.SUCCESS, message, $(this._commonSelectors.addToListOverlay).data(message));
			},

			doAddItems(deleteFromSource, targetCode) {
				this.$ctx = $(this._ctx);
				const savedItemListAddItemUrlInputSelector = this._commonSelectors.savedItemListAddItemUrlInput;
				const mergeData = this.getSavedItemListMergeDataRequest(targetCode, '');
				const url =
					`${this.$ctx.find(savedItemListAddItemUrlInputSelector).val()}?deleteFromSource=${deleteFromSource}`;
				this.showLoadingSpinner();
				$.ajax({
					url,
					type: 'POST',
					data: mergeData,
					traditional: true,
					success: (response) => {
						this.hideLoadingSpinner();
						if (response === 'OK') {
							this.refreshDetails();
							this.displayItemAddNotification(deleteFromSource);
						} else if (response === 'NOT_ACCEPTABLE') {
							const message = 'cart.notification.wishlist.copy.or.move.failed';
							addNotification(MessageType.ERROR, message, $(this._commonSelectors.addToListOverlay).data(message));
						}
						this.refreshMiniCart();
					},
					error: (xhr, textStatus, errorThrown) => {
						/* eslint-disable no-console */
						console.log('Adding failed, details below \n-----------START-----------');
						console.log(`xhr => ${xhr}`);
						console.log(`textStatus => ${textStatus}`);
						console.log(`errorThrown => ${errorThrown}`);
						console.log('----------END----------');
						/* eslint-enable no-console */
						this.hideLoadingSpinner();
					},
				});
			},

			disableCompleteButton() {
				$(this._selectors.completeOrderButton).each(function () {
					$(this).parent().css({
						cursor: 'not-allowed',
						opacity: '0.3',
					});
					$(this).css({
						'pointer-events': 'none',
					});
				});
			},

			enableCompleteButton() {
				$(this._selectors.completeOrderButton).each(function () {
					$(this).parent().css({
						cursor: 'pointer',
						opacity: '1',
					});
					$(this).css({
						'pointer-events': 'unset',
					});
				});
			},

			onClickCheckBoxEnableSaveButton() {
				const selectors = this._selectors;
				if ($('[data-js-edit-checkbox-cart]:checked').length > 0) {
					$(this._commonSelectors.addToListButton).removeAttr('disabled');
					$(selectors.deleteEntriesButton).removeAttr('disabled');
				} else {
					$(this._commonSelectors.addToListButton).attr('disabled', 'disabled');
					$(selectors.deleteEntriesButton).attr('disabled', 'disabled');
				}
			},

			onClickRemoveItemOverlayConfirmButton() {
				this.$ctx = $(this._ctx);
				let requestData = {};
				/* eslint-disable spaced-comment */
				if ($(this._commonSelectors.directDeleteEntryEnableCheckbox).prop('checked')) {
					//For deleting entry directly
					const isEmks = $(this._commonSelectors.directDeleteEntryEmksCheckbox).prop('checked');
					const value = [$(this._commonSelectors.directDeleteEntryValue).val()];
					requestData = {
						entryNumbers: isEmks ? [] : value,
						headPositions: isEmks ? value : [],
						CSRFToken: this.getCSRFToken(),
					};

				} else {
					//For Deleting entry from edit
					const mergeData = this.getSavedItemListMergeDataRequest('', '');
					requestData = {
						headPositions: mergeData.headPositions,
						entryNumbers: mergeData.entryNumbers,
						CSRFToken: mergeData.CSRFToken,
					};
				}
				this.showLoadingSpinner();
				/* eslint-enable spaced-comment */
				$.ajax({
					url: $(this._commonSelectors.removeItemUrlInput).val(),
					type: 'POST',
					data: requestData,
					traditional: true,
					success: () => {
						this.refreshDetails();
						this.refreshMiniCart();
						this.hideLoadingSpinner();
					},
					error: () => {
						/* eslint-disable no-console */
						console.log('Delete item failed.');
						/* eslint-enable no-console */
						this.hideLoadingSpinner();
					},
				});
				this.closeFancyBoxOverlay(this._commonSelectors.removeItemOverlay);
			},

			onClickUpdatePositionSalesOverlayConfirmButton() {
				this.$ctx = $(this._ctx);
				const requestData = {
					headPosition: $(this._commonSelectors.headPositionCode).val(),
					salesText: $(this._commonSelectors.salesTextOverlayInput).val(),
					CSRFToken: this.getCSRFToken(),
				};
				this.showLoadingSpinner();
				/* eslint-enable spaced-comment */
				$.ajax({
					url: $(this._commonSelectors.updateSalesTextUrlInput).val(),
					type: 'POST',
					data: requestData,
					traditional: true,
					success: () => {
						this.refreshDetails();
						this.refreshMiniCart();
						this.hideLoadingSpinner();
					},
					error: () => {
						/* eslint-disable no-console */
						console.log('Update sales text failed.');
						/* eslint-enable no-console */
						this.hideLoadingSpinner();
					},
				});
				this.closeFancyBoxOverlay(this._commonSelectors.updatePositionSalesOverlay);
			},

			showLoadingSpinner() {
				$('#js-loading-overlay-cart').show();
				$('[data-t-name="header"]').addClass('visual-header-overlay');
				document.querySelector("[data-t-name='App']").style.setProperty('overflow', 'hidden');
			},

			hideLoadingSpinner() {
				$('#js-loading-overlay-cart').hide();
				$('[data-t-name="header"]').removeClass('visual-header-overlay');
				document.querySelector("[data-t-name='App']").style.removeProperty('overflow');
			},

			deleteOldestSavedItemList() {
				const isSharedButtonChecked = $(this._commonSelectors.saveListOverlaySharedButton).prop('checked');
				const privacy = isSharedButtonChecked === true ? this._privacyNames.SHARED : this._privacyNames.PRIVATE;
				const oldestWishlistPromise = this.getSavedItemListToRemove(privacy);
				if (oldestWishlistPromise) {
					oldestWishlistPromise.then((oldestSavedItemListCodeToDelete) => {
						this.deleteSavedItemListByCode(oldestSavedItemListCodeToDelete)
							.catch((error) => {
								/* eslint-disable-next-line no-console */
								console.error(error);
							});
					});
				}
			},

			deleteSavedItemListByCode(code) {
				return new Promise((reject) => {
					$.ajax({
						url: `${$(this._commonSelectors.savedItemListUrl).val()}/${code}`,
						type: 'DELETE',
						traditional: true,
						success: () => {},
						error: () => {
							reject(new Error('Deleting saved item list failed'));
						},
					});
				});
			},

		}
	)
);
