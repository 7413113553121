const toggleHideClass = 'h-d-none';
const toggleInvisibleClass = 'h-invisible';
const toggleActiveClass = 'state-h-toggle--is-open';
const body = 'body';

/**
* Global function to toggle content
*
* @param {Object} $el jQuery object used for the clicked element.
* @param {string} target string used as a selector for the target for toggling content.
* @param {Object} $ctx jQuery object used for the context.
* @param {string} $hideMode [display|visibility] Use CSS properties 'display: none' or 'visibility: hidden' for toggling
* @returns {undefined}
*/
export function toggleContent($el, target, $ctx = $(body), $hideMode = 'display') {
	const moreToggleButton = $el.data('toggle-more');
	const lessToggleButton = $el.data('toggle-less');

	if ($el.hasClass(toggleActiveClass)) {
		$el
			.toggleClass(toggleActiveClass)
			.html(moreToggleButton);
	} else {
		$el
			.toggleClass(toggleActiveClass)
			.html(lessToggleButton);
	}

	$ctx
		.find(target)
		.toggleClass($hideMode === 'display' ? toggleHideClass : toggleInvisibleClass);
}

/**
 * Tests if a given element is active by checking the existence of toggleActiveClass
 *
 * @param {Object} $ctx jQuery context the contained element
 * @param {string} triggerSelector selector string of trigger element to be tested
 * @returns {boolean} active or not
 */
export function isActive($ctx, triggerSelector) {
	return $ctx.find(triggerSelector).hasClass(toggleActiveClass);
}

/**
 * Closes receiving component's toggable menu if
 * - its menu is open
 * - its not sender of the triggering event
 * so that toggable menus close each other when open.
 *
 * @param {Object} $ctx jQuery context of the receiving component
 * @param {string} sendingTriggerSelector selector string of the sending element
 * @param {string} receivingTriggerSelector selector string of receiving element
 * @param {string} receivingTargetSelector selector string of the target to be closed
 * @returns {undefined}
 */
export function onToggableMenuClicked(
	$ctx, sendingTriggerSelector,
	receivingTriggerSelector, receivingTargetSelector) {

	const $triggerElement = $ctx.find(receivingTriggerSelector);

	if (receivingTriggerSelector !== sendingTriggerSelector) {
		if (isActive($ctx, receivingTriggerSelector)) {
			toggleContent($triggerElement, receivingTargetSelector);
		}
	}
}
