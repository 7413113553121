const _slickGlobalConfigs = {
	infinite: true,
	mobileFirst: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
	cssEase: 'linear',
	draggable: false,
};

module.exports = { // eslint-disable-line no-undef
	_slickGlobalConfigs,
};
