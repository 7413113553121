import * as sharedVariables from '@dormakaba/one-ui/assets/css/variables/_variables.scss';
import T from 'terrific';
import $ from 'jquery';
import * as toggle from '../../../../assets/js/utils/toggle';

/**
 * Basic Module description.
 */
T.Module.ProductVariantList = T.createModule({
	_configs: {
		headerHeight: sharedVariables.headerHeight,
	},
	_attributes: {
		data: {
			moreLessContentToggleTarget: 'js-more-less-content-toggle-target',
			highlightedVariant: 'js-product-variant-list-highlighted-variant',
		},
	},
	_selectors: {
		moreLessContentToggle: '[data-js-more-less-content-toggle-trigger]',
	},
	_eventNames: {
		CLICK: 'click',
		TRANSITION_END: 'transitionend webkitTransitionEnd oTransitionEnd',
	},
	_stateClasses: {
		highlightedClass: 'm-product-variant-list__item--highlighted',
	},

	start(resolve) {
		this.$ctx = $(this._ctx);
		this.$ajaxCallEvent = true;
		this.showIfReferenced();

		this.$ctx.find(this._selectors.moreLessContentToggle).on(this._eventNames.CLICK, this.moreLessToggle.bind(this));

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	showIfReferenced() {
		const $highlightedVariantCode = this.$ctx.data(this._attributes.data.highlightedVariant);

		if ($highlightedVariantCode !== '') {
			const $referencedItem = this.$ctx.find(`#${$highlightedVariantCode}`);
			$referencedItem.addClass(this._stateClasses.highlightedClass);
			$referencedItem.on(this._eventNames.TRANSITION_END, (ev) => {
				$(window).scrollTop($(ev.currentTarget).offset().top - this._configs.headerHeight);
				$(this).off(ev);
			});
		}
	},

	moreLessToggle(ev) {
		const $el = $(ev.currentTarget);
		const target = $el.data(this._attributes.data.moreLessContentToggleTarget);

		toggle.toggleContent($el, target, this.$ctx);
	},

});
