import T from 'terrific';
import $ from 'jquery';
import * as Detect from 'detect-browser';
import * as Cookies from 'browser-cookies';
import '../media/browser-unsupport.png';
import '../media/apple_safari.png';
import '../media/chrome_logo.png';
import '../media/edge_logo.png';
import '../media/firefox.png';

/**
 * Basic Module description.
 */
T.Module.UnsupportedBrowserMsg = T.createModule({
	_selectors: {
		fancyboxUnsupportedBrowserContent: '#unsupported-browser-content',
		closeBtn: '[data-js-close-btn]',
		checkBox: '[data-js-check-box]',
		textMessage: '[data-js-text-msg]',
	},
	_eventNames: {
		CLICK: 'click',
		CHANGE: 'change',
	},
	_data: {
		infoText: 'js-unsupported-browser-msg-info-text',
	},
	_config: {
		cookieName: 'unsupportedBrowser',
	},

	// eslint-disable-next-line complexity
	start(resolve) {
		// bind ctx

		this.$ctx = $(this._ctx);
		this.$closeOverlay = this.$ctx.find(this._selectors.closeBtn);
		this.$checkedBox = this.$ctx.find(this._selectors.checkBox);
		this.$contentMessage = this.$ctx.find(this._selectors.textMessage);

		const browser = Detect.parseUserAgent(navigator.userAgent);
		this.$contentMessage.append(this.getInfoText(browser));

		if (Cookies.get(this._config.cookieName) === null) {
			switch (browser && browser.name) {
				case 'chrome':
					if (parseFloat(browser.version) < 71) {
						$.fancybox.open({
							src: this._selectors.fancyboxUnsupportedBrowserContent,
						});
					}
					break;
				case 'firefox':
					if (parseFloat(browser.version) < 64) {
						$.fancybox.open({
							src: this._selectors.fancyboxUnsupportedBrowserContent,
						});
					}
					break;
				case 'edge':
					if (parseFloat(browser.version) < 16) {
						$.fancybox.open({
							src: this._selectors.fancyboxUnsupportedBrowserContent,
						});
					}
					break;
				case 'safari':
					if (parseFloat(browser.version) < 10) {
						$.fancybox.open({
							src: this._selectors.fancyboxUnsupportedBrowserContent,
						});
					}
					break;
				default:
			}
		}

		this.$closeOverlay.on(this._eventNames.CLICK, () => {
			$.fancybox.close();
		});

		this.$checkedBox.on(this._eventNames.CHANGE, (ev) => {
			// eslint-disable-next-line no-unused-expressions
			ev.target.checked ? this.setCookieCloseOverlay() : this.removeCookieOverlay();
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	getInfoText(browser) {
		return this.$ctx.data(this._data.infoText)
			.replace('___BROWSER_NAME___', `<span class='a-text a-text--bold'>${browser.name}`)
			.replace('___BROWSER_VERSION___', `${browser.version}</span>`);
	},

	setCookieCloseOverlay() {
		Cookies.set(this._config.cookieName, window.location.hostname, { expires: 999 });
	},

	removeCookieOverlay() {
		Cookies.erase(this._config.cookieName);
	},
});
