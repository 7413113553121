import T from 'terrific';

T.EventName = T.EventName || {};
/**
 * Event names used for global component communication
 *
 * @namespace T.EventName.Utils
 * @class Component
 */
T.EventName.Utils = {
	LAYER_ONDEMAND: 'EventName.Utils.LayerOnDemand',
};
