import 'lazysizes';
import $ from 'jquery';
import T from 'terrific';
import { startComponents } from '@gondel/core';

const requireAll = (requireContext) => {
	return requireContext.keys().map(requireContext);
};

// Get styles from one-ui assets
requireAll(require.context('../assets/css', true, /\/*\.scss$/));

// Get styles from one-ui components
requireAll(require.context('./', true, /css\/[a-z-]*\.scss$/));
requireAll(require.context('./', true, /css\/modifier\/[a-z0-9\-.]*\.scss$/));

// Get JavaScript from one-ui assets
requireAll(require.context('../assets/js', true, /^((?![\\/]node_modules|spec[\\/]).)*\.js$/));

// Get gondel scripts from one-ui components
// e.g. ./datepicker/js/datepicker.ts, ./datepicker/js/datepicker.js
// folder = filename
requireAll(require.context('./atoms/', true, /^\.[\\/]([^\\/]+)[\\/]js[\\/]\1\.(ts|js)$/));
requireAll(require.context('./molecules/', true, /^\.[\\/]([^\\/]+)[\\/]js[\\/]\1\.(ts|js)$/));


/*
 * Configure lazyload plugin.
 */
const lazyloadConfig = () => {
	window.lazySizesConfig = window.lazySizesConfig || {};
	window.lazySizesConfig.lazyClass = 'has-lazyload';
	window.lazySizesConfig.loadedClass = 'is-lazyloaded';
	window.lazySizesConfig.srcAttr = 'data-lazyload-src';
	window.lazySizesConfig.srcsetAttr = 'data-lazyload-srcset';
	window.lazySizesConfig.customMedia = {
		'--sm': '(min-width: 640px)',
		'--md,': '(min-width: 1024px)',
		'--lg': '(min-width: 1440px)',
		'--xl': '(min-width: 1650px)',
	};
	window.lazySizesConfig.init = false;
};

const bootstrapLazyload = () => {
	window.lazySizes.init();
};

/*
 * Starts the terrific app.
 */
const bootstrapTerrific = () => {
	const rootElement = document.querySelector('body');
	const terrificConfig = JSON.parse(document.querySelector('#terrific-config').innerHTML);
	const application = new T.Application(rootElement, terrificConfig);
	application.registerModules();
	application.start();

	window.dormakaba.emitter = new T.EventEmitter(application._sandbox);
};

/*
 * Starts the gondel app.
 */
const bootstrapGondel = () => {
	startComponents();
};

lazyloadConfig();

/*
 * When the DOM is ready, trigger terrific and gondel.
 */
$(document).ready(() => {
	bootstrapTerrific();
	bootstrapGondel();
	bootstrapLazyload();
});
