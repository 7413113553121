import T from 'terrific';
import $ from 'jquery';
import * as toggle from '../../../../assets/js/utils/toggle'; // eslint-disable-line import/no-unresolved

/**
 * Header search molecule. Gets instantiated/inserted in DOM for trigger (button)
 * as well as for initially hidden, actual element (target) holding the search form,
 * each for mobile|desktop.
 */
T.Module.HeaderSearch = T.createModule({
	_attributes: {
		targetDevice: 'data-js-trigger-target-device',
	},
	_selectors: {
		toggleTrigger: '[data-js-header-search-toggle-trigger]',
		searchButton: '[data-js-header-search-button]',
		searchForm: '[data-js-header-search-form]',
		vanguardSearchConfig: '[data-js-vanguard-search-config]',
	},
	_eventNames: {
		CLICK: 'click',
	},
	_data: {
		// Target selectors of elements to be toggled, so component knows its
		// selectors itself and is not relevant on having them passed as a value.
		targetSelectors: {
			mobile: '[data-js-header-search-bar-mobile]',
			desktop: '[data-js-header-search-bar-desktop]',
		},
	},

	start(resolve) {
		// bind ctx
		this.$ctx = $(this._ctx);

		this.$ctx.find(this._selectors.toggleTrigger)
			.on(this._eventNames.CLICK, (ev) => {
				this.toggleSearchBar(ev);
			});

		this.$ctx.find(this._selectors.searchButton)
			.on(this._eventNames.CLICK, (ev) => {
				this.onClickSearch(ev);
			});

		this._events.on('closeActiveToggle', () => {
			// Condition to prevent multiple execution
			if (toggle.isActive(this.$ctx, this._selectors.toggleTrigger)) {
				this.$ctx
					.find(this._selectors.toggleTrigger)
					.trigger(this._eventNames.CLICK);
			}
		});

		// Event gets received by all instances (triggers, targets, mobile|desktop)
		this._events.on('toggableMenuClicked', (targetDevice, sendingTriggerSelector) => {
			toggle.onToggableMenuClicked(
				this.$ctx,
				sendingTriggerSelector,
				this._selectors.toggleTrigger,
				this._data.targetSelectors[targetDevice]
			);
		});

		resolve();
	},

	sync() {
		// Called when start() method of all registered modules was called.
	},

	toggleSearchBar(ev) {
		const $vanguardSearchConfig = this.$ctx.find(this._selectors.vanguardSearchConfig).eq(0);

		if ($vanguardSearchConfig.data('active')) {
			window.location.href = $vanguardSearchConfig.data('url');
			return;
		}

		const $el = $(ev.currentTarget);

		const targetDevice = $el.attr(this._attributes.targetDevice);
		const targetSelector = this._data.targetSelectors[targetDevice];

		this._events.emit('toggableMenuClicked', targetDevice, this._selectors.toggleTrigger);

		toggle.toggleContent($el, targetSelector);

		if (toggle.isActive(this.$ctx, this._selectors.toggleTrigger)) {
			this._events.emit('bindClickToBodyToCloseActiveToggle');
		} else {
			this._events.emit('unBindClickToBodyToCloseActiveToggle');
		}
		this._events.emit('closeGlobalMessage');
	},
	onClickSearch() {
		const searchForm = this.$ctx.find(this._selectors.searchForm);

		searchForm.submit();
	},
});
